.grid {
	$mobileColMarginBottom: 2rem;
	$self: &;

	display: block;
	width: 100%;

	&--vc {
		align-items: center;
	}

	&--vb {
		align-items: flex-end;
	}


	&--hc {
		text-align: center;
	}

	&--hr {
		text-align: right;
	}

	&--dark-bg {
		//helper class for child elements
	}

	&--light-bg {

	}

	&--bgc {
		background-color: var(--bgc, transparent);

		padding-top: 5rem;
		//padding-bottom: 5rem;

		& + & {
			padding-top: 0 !important;
		}

		& + .grid__container:not(.grid--bgc) {
			padding-top: 5rem;
			//padding-bottom: 5rem;
		}

		&:last-of-type,
		&:last-child {
			padding-bottom: 5rem;
		}

		//&:first-child {
		//	padding-top: 6rem;
		//}
		//
		//&:last-child {
		//	padding-bottom: 5rem;
		//}
	}

	&__container {
		display: grid;

		width: 100%;
		min-width: var(--layout-min-width);
		max-width: var(--layout-max-width);
		margin:0 auto;

		position: relative;
		z-index: $z-above-lines;

		//& + & {
		//	margin-top: 5rem;
		//	@include respond-to(display) {
		//		margin-top: 10rem;
		//	}
		//}

		& + & {
			//margin-top: 5rem;
		}

		&#{$self}--nmt,
		#{$self}--nmt & {
			margin-top: 0;
		}

		&[data-triangle-listener="listening"] {
			padding-top: var(--layout-triangle-top-modifier);
		}

		grid-template-columns:
			[outer-column] var(--layout-grid-outer-column-width)
			[outer-column-end column-space] var(--layout-grid-gap-half)
			[column-line] 0.1rem [column-line-end]
			repeat(11,
				[column-start] 1fr
				[column-end column-space] var(--layout-grid-gap-half)
				[column-line] 0.1rem
				[column-line-end column-space] var(--layout-grid-gap-half))
			[column-start] 1fr
			[column-end column-line]  0.1rem
			[column-line-end column-space] var(--layout-grid-gap-half)
			[outer-column] var(--layout-grid-outer-column-width) [outer-column-end];

		#{ $self }--33 &,
		&#{ $self }--33 {
			grid-row-gap: 2.5rem;
		}

		#{ $self }--footer & {
//			grid-auto-rows: 22rem auto;
			grid-template-rows: 22rem auto;
		}

		#{ $self }--timeline & {
			//grid-row-gap: 10rem;
		}

		#{ $self }--blog &,
		&#{ $self }--blog {
			grid-row-gap: 3.2rem;
			@include respond-to(tablet) {
				grid-row-gap: 6.4rem;
			}


		}

		#{ $self }--numbers &,
		&#{ $self }--numbers {
			grid-row-gap: 12.5rem;
		}

		#{ $self }--jobs &,
		&#{ $self }--jobs {
			grid-row-gap: 3.2rem;
		}

		#{ $self }--uic &,
		&#{ $self }--uic {

			& + & {
				padding-top: 3rem;
			}
		}

	}

	&__column {
		//display: grid;
		//grid-template-columns: inherit;

		grid-column: column-start 1 / column-end 12;

		&--hc{
			text-align: center;
		}

		&--hr {
			text-align: right;
		}

		#{ $self }--100 &,
		#{ $self }--100-narrow &,
		#{ $self }--100-narrower & {
			grid-column: column-start 1 / column-end 12;
		}

		#{ $self }--100-narrow & {
			@include respond-to(tablet) {
				grid-column: column-start 2 / column-end 11;
			}
		}

		#{ $self }--100-narrower & {
			@include respond-to(tablet) {
				grid-column: column-start 3 / column-end 10;
			}
		}

		#{ $self }--50 &,
		#{ $self }--50-narrow &,
		#{ $self }--50-narrower &,
		#{ $self }--50-wide-left &,
		#{ $self }--50-wide-right &,
		#{ $self }--50-wide-left-narrow &,
		#{ $self }--50-wide-right-narrow & {
			margin-bottom: $mobileColMarginBottom;
			grid-column: column-start 1 / column-end 12;
			&:nth-child(2n+2) {
				grid-column: column-start 1 / column-end 12;
			}

			@include respond-to(tablet) {
				margin-bottom: 0;
				grid-column: column-start 1 / column-end 6;
				&:nth-child(2n+2) {
					grid-column: column-start 7 / column-end 12;
				}
			}
		}

		#{ $self }--50-narrow & {
			@include respond-to(tablet) {
				grid-column: column-start 2 / column-end 6;
				&:nth-child(2n+2) {
					grid-column: column-start 7 / column-end 11;
				}
			}
		}
		#{ $self }--50-narrower & {
			@include respond-to(tablet_landscape) {
				grid-column: column-start 3 / column-end 6;
				&:nth-child(2n+2) {
					grid-column: column-start 7 / column-end 10;
				}
			}
		}


		#{ $self }--50-wide-left & {
			@include respond-to(tablet) {
				grid-column: column-line 1 / column-line 7;
				&:nth-child(2n+2) {
					grid-column: column-start 8 / column-end 12;
				}
			}
		}
		#{ $self }--50-wide-right & {
			@include respond-to(tablet) {
				grid-column: column-start 1 / column-end 5;
				&:nth-child(2n+2) {
					grid-column: column-line 7 / column-line 13;
				}
			}
		}

		#{ $self }--50-wide-left-narrow & {
			@include respond-to(tablet) {
				grid-column: column-line 1 / column-line 7;
				&:nth-child(2n+2) {
					grid-column: column-start 8 / column-end 11;
				}
			}
		}
		#{ $self }--50-wide-right-narrow & {
			@include respond-to(tablet) {
				grid-column: column-start 2 / column-end 5;
				&:nth-child(2n+2) {
					grid-column: column-line 7 / column-line 13;
				}
			}
		}


		#{ $self }--33 &,
		#{ $self }--blog & {

			@include respond-to(tablet) {
				grid-column: column-start 1 / column-end 6;
				&:nth-child(2n+2) {
					grid-column: column-start 7 / column-end 12;
				}
			}

			@include respond-to(tablet_landscape) {
				grid-column: column-start 1 / column-end 4;
				&:nth-child(2n+2) {
					grid-column: column-start 1 / column-end 4;
				}

				&:nth-child(3n+2) {
					grid-column: column-start 5 / column-end 8;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 9 / column-end 12;
				}
			}
		}



		#{ $self }--blog-listing & {
			grid-column: column-start 1 / column-end 12;

			@include respond-to(tablet_landscape) {
				&:first-child {
					grid-column: column-start 1 / column-end 12;
				}

				&:nth-child(2n+2) {
					grid-column: column-start 1 / column-end 6;
				}
				&:nth-child(2n+3) {
					grid-column: column-start 7 / column-end 12;
				}
			}

			@include respond-to(display) {
				&:first-child {
					grid-column: column-start 1 / column-end 12;
				}

				&:nth-child(3n+2) {
					grid-column: column-start 1 / column-end 4;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 5 / column-end 8;
				}
				&:nth-child(3n+4) {
					grid-column: column-start 9 / column-end 12;
				}
			}

			@include respond-to(display_large) {
				&:first-child {
					grid-column: column-start 1 / column-end 12;
				}

				&:nth-child(3n+2) {
					grid-column: column-start 1 / column-end 4;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 5 / column-end 8;
				}
				&:nth-child(3n+4) {
					grid-column: column-start 9 / column-end 12;
				}

				&:nth-child(4n+5) {
					grid-column: column-start 1 / column-end 3;
				}
				&:nth-child(4n+6) {
					grid-column: column-start 4 / column-end 6;
				}
				&:nth-child(4n+7) {
					grid-column: column-start 7 / column-end 9;
				}
				&:nth-child(4n+8) {
					grid-column: column-start 10 / column-end 12;
				}
			}

		}


		#{ $self }--25 & {
			margin-bottom: $mobileColMarginBottom;

			@include respond-to(tablet) {

				grid-column: column-start 1 / column-end 6;
				&:nth-child(2n+2) {
					grid-column: column-start 7 / column-end 12;
				}
			}

			@include respond-to(display) {
				margin-bottom: 0;
				grid-column: column-start 1 / column-end 3;
				&:nth-child(4n+2) {
					grid-column: column-start 4 / column-end 6;
				}
				&:nth-child(4n+3) {
					grid-column: column-start 7 / column-end 9;
				}
				&:nth-child(4n+4) {
					grid-column: column-start 10 / column-end 12;
				}
			}
		}
		#{ $self }--16 & {
			grid-column: column-start 1 / column-end 2;
			&:nth-child(6n+2) {
				grid-column: column-start 3 / column-end 4;
			}
			&:nth-child(6n+3) {
				grid-column: column-start 5 / column-end 6;
			}
			&:nth-child(6n+4) {
				grid-column: column-start 7 / column-end 8;
			}
			&:nth-child(6n+5) {
				grid-column: column-start 9 / column-end 10;
			}
			&:nth-child(6n+6) {
				grid-column: column-start 11 / column-end 12;
			}
		}


		/* specials */

		#{ $self }--33-async & {
			grid-column: outer-column 1 / column-line 6;
			&:nth-child(3n+2) {
				display: none;
			}
			&:nth-child(3n+3) {
				grid-column: column-start 8 / outer-column-end 2;
			}

			@include respond-to(display) {
				grid-column: outer-column 1 / column-line 5;
				&:nth-child(3n+2) {
					display: block;
					grid-column: column-start 6 / column-line 9;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 10 / outer-column-end 2;
				}
			}

		}

		#{ $self }--header & {
			grid-column: column-start 1 / column-end 12;
		}

		#{ $self }--footer & {

			grid-column: outer-column 1 / outer-column-end 2;
			&:nth-child(2n+2) {
				grid-column: column-space 1 / outer-column-end 2;
			}

			@include respond-to(tablet_landscape) {
				grid-column: outer-column 1 / column-end 12;
				&:nth-child(2n+2) {
					grid-column: column-space 1 / outer-column-end 2;
				}
			}
		}

		#{ $self }--numbers & {

			@include respond-to(display) {
				grid-column: column-start 1 / column-end 4;
				&:nth-child(3n+2) {
					grid-column: column-start 5 / column-end 8;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 9 / column-end 12;
				}
			}
		}

		#{ $self }--jobs & {

			grid-column: column-start 1 / column-end 12;

			//@include respond-to(tablet) {
			//	grid-column: column-start 1 / column-end 6;
			//	&:nth-child(2n+2) {
			//		grid-column: column-start 7 / column-end 12;
			//	}
			//}

			@include respond-to(display) {
				grid-column: column-start 1 / column-end 4;

				&:nth-child(3n+2) {
					grid-column: column-start 5 / column-end 8;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 9 / column-end 12;
				}
			}
		}

		//#{ $self }--blog & {
		//
		//	grid-column: column-start 1 / column-end 12;
		//	@include respond-to(tablet_landscape) {
		//		grid-column: column-start 1 / column-end 6;
		//		&:nth-child(2n+2) {
		//			grid-column: column-start 7 / column-end 12;
		//		}
		//	}
		//
		//	@include respond-to(display) {
		//		grid-column: column-start 2 / column-end 6;
		//		&:nth-child(2n+2) {
		//			grid-column: column-start 7 / column-end 11;
		//		}
		//	}
		//}


		//new job detail
		#{ $self }--job & {
			margin-bottom: $mobileColMarginBottom;

			@include respond-to(tablet) {
				grid-column: column-start 1 / column-end 4;
				&:nth-child(2n+2) {
					grid-column: column-start 5 / column-end 12;
				}
				//&:nth-child(3n+3) {
				//	display: none;
				//}

				&:nth-child(3n+3) {
					grid-column: column-start 5 / column-end 12;
				}
			}

			@include respond-to(display) {
				margin-bottom: 6rem;
				grid-column: column-start 1 / column-end 3;
				&:nth-child(2n+2) {
					grid-column: column-start 4 / column-end 9;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 10 / column-end 12;
				}
			}
		}

		//new blog
		#{ $self }--blogdetail-intro & {
			margin-bottom: $mobileColMarginBottom;

			@include respond-to(tablet) {
				grid-column: column-start 1 / column-end 6;
				&:nth-child(2n+2) {
					grid-column: column-start 7 / column-end 12;
				}
			}

			@include respond-to(display) {
				margin-bottom: 0;
				grid-column: column-start 1 / column-end 3;
				&:nth-child(2n+2) {
					grid-column: column-start 4 / column-end 9;
				}
			}
		}

		#{$self}--100-5-pre3 & {
			@include respond-to(display) {
				margin-bottom: 0;
				grid-column: column-start 4 / column-end 9;
			}
		}

		#{$self}--50-3-5-pre3 & {
			margin-bottom: $mobileColMarginBottom;
			grid-column: column-start 1 / column-end 6;
			&:nth-child(2n+2) {
				grid-column: column-start 7 / column-end 12;
			}

			@include respond-to(display) {
				margin-bottom: 0;
				grid-column: column-start 4 / column-end 6;
				&:nth-child(2n+2) {
					grid-column: column-start 7 / column-end 12;
				}
			}
		}

		#{$self}--33-3-6-3 & {
			@include respond-to(tablet) {
				grid-column: column-start 1 / column-end 6;
				&:nth-child(3n+2) {
					grid-column: column-start 7 / column-end 12;
				}
			}

			@include respond-to(tablet_landscape) {
				grid-column: column-start 1 / column-end 3;
				&:nth-child(3n+2) {
					grid-column: column-start 4 / column-end 9;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 10 / column-end 12;
				}
			}
		}

		#{$self}--33-3-3-6 & {
			@include respond-to(tablet) {
				grid-column: column-start 1 / column-end 6;
				&:nth-child(3n+2) {
					grid-column: column-start 7 / column-end 12;
				}
			}

			@include respond-to(tablet_landscape) {
				grid-column: column-start 1 / column-end 3;
				&:nth-child(3n+2) {
					grid-column: column-start 4 / column-end 6;
				}
				&:nth-child(3n+3) {
					grid-column: column-start 7 / column-end 12;
				}
			}
		}

		//new blog end


		#{ $self }--tabs & {
			grid-column: column-start 1 / column-end 12;
		}

		#{ $self }--intro & {


			&:first-child {
				grid-column: column-start 1 / column-end 12;
			}
			&:last-child {
				grid-column: column-start 1 / column-end 12;
			}

			@include respond-to(tablet_landscape) {
				&:first-child {
					grid-column: column-start 1 / column-end 8;
				}
				&:last-child {
					//grid-column: column-start 6 / column-end 11;
					grid-column: column-start 9 / column-end 12;
					//margin-top: 10rem;
				}
			}
			@include respond-to(display) {
				&:first-child {
					grid-column: column-start 1 / column-end 6;
				}
				&:last-child {

					grid-column: column-start 7 / column-end 12;
					margin-top: 10rem;
				}

			}
		}

		#{ $self }--accordion & {
			&.accordion__header,
			&.accordion__content {
				grid-column: column-start 1 / column-end 12;
				@include respond-to(display) {
					grid-column: column-start 3 / column-end 10;
				}
			}

		}

		#{ $self }--quote & {
			&:first-child {
				grid-column: outer-column 1 / column-end 1;
				@include respond-to(tablet_landscape) {
					grid-column: column-line 1 / column-end 1;
				}
			}

			&:nth-child(2n) {
				grid-column: column-start 2 / column-end 11;
			}

			&:last-child {
				grid-column: column-start 12 / outer-column-end 2;
				@include respond-to(tablet_landscape) {
					grid-column: column-start 12 / column-line-end 13;
				}
				align-self: flex-end;
			}

		}

		#{ $self }--timeline-left &,
		#{ $self }--timeline-right & {
			grid-row: 1;

			&.timeline__content {
				grid-column: column-start 4 / column-end 12;
			}

			//--center
			&.timeline__cube-wrapper {
				grid-column: column-start 1 / column-end 3;
			}

			@include respond-to(display) {
				//left
				&.timeline__content {
					//grid-column: column-start 1 / column-end 4;
					grid-column: column-start 1 / column-start 5
				}

				//--center
				&.timeline__cube-wrapper {
					grid-column: column-start 6 / column-end 7;
					justify-self: center;
				}

				//&--right{
				//	grid-column: column-start 9 / column-end 12;
				//}
			}


		}

		#{ $self }--timeline-right & {

			@include respond-to(display) {
				//--right
				&.timeline__content {
					//grid-column: column-start 9 / column-end 12;
					grid-column: column-end 8 / column-end 12;
				}
			}
		}


		#{ $self }--teaser-list-left &,
		#{ $self }--teaser-list-right & {
			grid-row: 1;
		}

		#{ $self }--teaser-list-left & {
			&.teaser-list__content {
				grid-column: column-start 1 / column-end 5;
			}

			&.teaser-list__media {
				grid-column: column-start 6 / outer-column-end 2;
			}

		}

		#{ $self }--teaser-list-right & {
			&.teaser-list__content {
				grid-column: column-start 7 / column-end 11;
				padding-left: 4rem;
			}

			&.teaser-list__media {
				grid-column: outer-column 1 / column-end 6;
			}
		}

		#{ $self }--teaser-list-last & {
			grid-column: column-start 1 / column-end 12;
			justify-self: center;
		}


		#{ $self }--lines & {
			grid-column: column-line 1 / column-line-end 1;
			&:nth-child(7n+2) { grid-column: column-line 3 / column-line-end 3 }
			&:nth-child(7n+3) { grid-column: column-line 5 / column-line-end 5 }
			&:nth-child(7n+4) { grid-column: column-line 7 / column-line-end 7 }
			&:nth-child(7n+5) { grid-column: column-line 9 / column-line-end 9 }
			&:nth-child(7n+6) { grid-column: column-line 11 / column-line-end 11 }
			&:nth-child(7n+7) { grid-column: column-line 13 / column-line-end 13 }
		}

		#{ $self }--triangle & {
			//grid-column: outer-column 1 / column-start 5;
			grid-column: outer-column 1 / column-start 7;
			@include respond-to(tablet_landscape) {
				grid-column: outer-column 1 / column-end 5;
			}
			@include respond-to(display) {
				grid-column: outer-column 1 / column-start 5;
			}
		}

		/* divers */

		#{ $self }--test & {
			min-height: 10rem;
			background: rgba(34,19,33,0.4);
			margin-bottom: 3rem;
		}
	}

}

/* old tests
.old-grid {
	$self: &;

	display: grid;
	grid-template-rows: auto;
	grid-template-columns: var(--global-layout-grid-template-columns);

	&__container {
		grid-column: 2/2;

		display: grid;
		grid-template-rows: auto;
		grid-template-columns : repeat(12, 1fr);
		grid-column-gap: var(--global-layout-grid-template-column-gap);

		&--100 {
			--layout-grid-column-span: span 12;
		}
		&--50 {
			--layout-grid-column-span: span 6;
		}
		&--33 {
			--layout-grid-column-span: span 4;
		}
		&--25 {
			--layout-grid-column-span: span 6;
			@include respond-to(display) {
				--layout-grid-column-span: span 3;
			}
		}
		&--16 {
			--layout-grid-column-span: span 2;
		}
	}

	&__column {
		grid-column: var(--layout-grid-column-span);
	}
}

.grid-wide {
	$self: &;

	display: grid;
	grid-template-rows: auto;

	grid-template-columns: minmax(6.6rem, auto) minmax(auto, 130.8rem) minmax(6.6rem, auto);

	&__container {
		grid-column: 2/2;

		display: grid;
		grid-template-rows: auto;
		grid-template-columns : repeat(12, 1fr);
	}

	&__column {
		grid-column: 1/13;
		background: var(--color-mint-dark);
		min-height: 20rem;
		margin-bottom: 5rem;
	}
}

.grid {
	$self: &;


	width: 100%;
	max-width: 76.8rem;
	margin:0 auto;
	display: block;

	@include respond-to(tablet) {
		min-width: 76.8rem;
		max-width: 128rem;
	}
	@include respond-to(display) {
		min-width: 128rem;
		max-width: 144rem;
	}

	&__container {
		display: grid;

		--gap: 0.8rem;
		--gap-half: 0.4rem;
		@include respond-to(tablet) {
			--gap: 2rem;
			--gap-half: 1rem;
		}
		@include respond-to(display) {
			--gap: 2.9rem;
			--gap-half: 1.45rem;
		}

		grid-template-columns: 1fr var(--gap-half) repeat(11, [column-start] 1fr [column-end] var(--gap-half) var(--gap-half)) [column-start] 1fr [column-end] var(--gap-half) 1fr;

		&--100 {
			--layout-grid-column-span: column-start 1 / column-end 11;
		}

		&--50 {
			--layout-grid-column-span: 3 / span 16;
		}

	}

	&__column {
		grid-column: var(--layout-grid-column-span);
		grid-column: column-start 1 / column-end 11;


		background: var(--color-coral);
		height: 20rem;
		margin-bottom: 2rem;
	}


	.column {
		grid-column: span 1;
		background: red;
		height: 20rem;
	}


}

 */