.to-top {
	$self: &;

	position: fixed;
	bottom: 0;
	//right: 0;
	left:0;

	z-index: $z-totop;

	@include respond-to(tablet) {
		left: unset;
		bottom: 12rem;
		right: 4rem;
	}

	@include respond-to(until_tablet) {
		.button {
			margin:0;
		}

		.button__label {
			display: none;
		}
	}

	opacity: 0;
	pointer-events: none;

	&--clickable {
		pointer-events: auto;

		&:hover {
			opacity: 1 !important;
		}
	}



}