
.xhr-curtain {
	$self: &;

	position: fixed;
	left: 0;
	top:0;
	width: 100%;
	height: 100%;
	z-index: $z-xhr-curtain;

	display: none;
	//pointer-events: none;
	user-select: none;

	&__label {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		display: none;
	}

	&__basic {
		position: absolute;
		top:100%;
		left:0;
		width: 100%;
		height: 50%;
		background: $color-forrest;
		//background: $color-mint-green;
		//background: linear-gradient(135deg, rgba(36,74,76,1) 0%, rgba(73,118,110,1) 21%, rgba(152,210,181,1) 100%);
		//background: linear-gradient(0deg, rgba(36,74,76,1) 0%, rgba(152,210,181,1) 100%);
	}

	/* frame
	&__frame {
		//transition: transform 1200ms cubic-bezier(0.19, 1, 0.22, 1);
		position: absolute;

		background: $color-forrest;

		&--top,
		&--bottom {
			top:0;
			width: 100%;
			height: 50.0%;
		}

		&--bottom {
			top: 50%;
		}

		&--left,
		&--right {
			width: 50.0%;
			height: 100%;
		}

		&--right {
			left: 50%;
		}

	}*/

	/*
	&__basic {
		position: absolute;
		top:0;
		left:0;
		width: 0;
		height: 100%;
		background: $color-forrest;
	}*/

	/* diag simple
	&__basic {
		position: absolute;
		top:0;
		left:0;

		width: 400%;
		transform: translateX(-150%) skew(-45deg);

		@include respond-to(tablet) {
			width: 200%;
			transform: translateX(-100%) skew(-45deg);
		}

		height: 100%;

		background: $color-forrest;

	}*/


	/* diag animation
	&__line-wrapper {
		position: absolute;
		top:0;
		left:0;
		z-index: 5;
		width: 100%;
		height: 100%;

		display: flex;
		flex-wrap: nowrap;

		transform: skew(-45deg) translateX(-50%);

	}

	&__line {
		#w: 100%;
		position: absolute;
		//left: calc(#{#w - 0.5} * var(--i));
		top:0;

		flex-shrink: 0;
		transform-origin: bottom;
		width: #w;
		height: 100%;
		background: #90c5ab;

		#wLeft: 63%;
		#wCenter: #w / 3;
		#wRight: #w * 1.5;
		#overlapp: 0.0;

		&:first-child {
			left: 0;
			width: #wLeft;
		}

		&:nth-child(2) {
			width: #wCenter;
			left: #{#wLeft - #overlapp};
			background: #84b69e;
			//background: var(--gradient-2);
		}

		&:last-child {
			width: #wRight;
			left: #{(#wLeft - #overlapp) + (#wCenter - #overlapp)};
		}


	}
	*/
}