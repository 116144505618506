.lines {
	$self: &;

	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100vh;
	z-index: $z-lines;
	opacity: 0.15;

	pointer-events: none;
	user-select: none;

	.grid__container {
		width: 100%;
		height: 100%;
	}

	.grid__column {
		height: 100%;
		background: var(--color-theme-lines);
	}

	display: none;
}