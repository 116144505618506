* {
	box-sizing : border-box;
}


@import "../vendor/susy/susy";
@import "../vendor/breakpoint/breakpoint";
@import "../vendor/normalize.scss";

@import "../../../../node_modules/flickity/css/flickity";
@import "../../../../node_modules/flickity-fade/flickity-fade";
@import "../../../../node_modules/mobius1-selectr/dist/selectr.min";
@import "../../../../node_modules/dropzone/dist/min/dropzone.min";
@import "../../../../node_modules/sweetalert2/dist/sweetalert2.min";

@import "helpers/mixins.scss";

@import "variables.scss";
@import "fonts.scss";

* {
	box-sizing                  : border-box;
	-webkit-touch-callout       : none; /* prevent callout to copy image, etc when tap to hold */
	-webkit-text-size-adjust    : none; /* prevent webkit from resizing text to fit */
	-webkit-tap-highlight-color : rgba(0, 0, 0, 0); /* prevent tap highlight color / shadow */
	//-webkit-user-select:none;                  /* prevent copy paste, to allow, change 'none' to 'text' */

	&:focus {
		outline-style : none;
		box-shadow    : none;
		border-color  : transparent;
	}
}

HTML, BODY {
	height : 100%;
	width  : 100%;
}

BODY {
	margin                     : 0;
	padding                    : 0;
	-webkit-overflow-scrolling : touch;
	overflow-x                 : hidden;


	display: grid;
	grid-template-rows: 1fr auto;
	grid-template-columns: 100%;
	
	grid-template-areas:
		'page-content'
		'page-footer'
	;


	width: 100%;
	min-height: 100%;
	height: auto;
	margin:0 auto;

	background: var(--color-page-background);

	&.layout--elements {
		--color-page-background: var(--color-grey-4);
	}
}

A {

}

.wrapper {
	$self: &;

	grid-area: page-content;
	min-height: var(--layout-triangle-dimension);




	.layout--elements & {

	}

	.theme-container SECTION:first-of-type:not(.seperator) {
		padding-top: var(--layout-header-height);

		&[data-triangle-listener=""] {
			padding-top: calc(var(--layout-header-height) * 2);
		}

		&[data-triangle-listener="listening"] {
			padding-top: var(--layout-triangle-top-modifier, var(--layout-header-height));
		}
	}

	//.layout--index &,
	//.layout--has-triangle &,
	//&--without-padding {
	//	SECTION:first-of-type {
	//		padding-top: 0;
	//	}
	//}

}

label A,
.text-link,
.wrapper P A[href],
.wysiwyg A[href] {
	/**
	@todo rework styles
	 */


	--text-color: inherit;
	--color: var(--color-mint-darker);
	--color-hover: var(--color-black);

	color: var(--color);
	padding-bottom: 0.05rem;
	transition: all $tt-cta linear;
	border-bottom: 0.2rem solid var(--color);
	//background: linear-gradient(
	//		to bottom,
	//		var(--link-color) 0%,
	//		var(--link-color) 98%
	//);
	//background-size: 0px 0.2rem;
	background-size: 100% 0.2rem;
	background-repeat: no-repeat;
	background-position: left 100%;

	//&:visited {
	//	color: var(--color-forrest);
	//	background: linear-gradient(
	//			to bottom,
	//			var(--color-forrest) 0%,
	//			var(--color-forrest) 98%
	//	);
	//	background-size: 100% 0.2rem;
	//	background-repeat: no-repeat;
	//	background-position: left 100%;
	//}

	&:focus,
	&:hover,
	&:active {
		--color: var(--color-hover);

		//color: var(--link-color-hover);

		//background: linear-gradient(
		//		to bottom,
		//		var(--link-color-hover) 0%,
		//		var(--link-color-hover) 98%
		//);
		//background-size: 100% 0.2rem;
		//background-repeat: no-repeat;
		//background-position: left 100%;
	}

	.icon,
	.inline-svg {
		height: 3rem;
		margin-right: 1rem;

		svg {
			width: auto;
			height: 3rem;
			fill: var(--color);
		}
	}


	&.disabled,
	&[disabled] {
		opacity: 0.5;
		pointer-events: none;
	}

}

IMG {
	//max-width: 100%;
	width  : 100%;
	height: auto;

	&.auto-height {
		height: auto;
	}

	&.lazy-image {
		//width: var(--iw);
		//height: var(--ih);

		&.loaded {
			width: 100%;
			height: auto;
		}
		//height: unset;
	}

}

PICTURE,
IMG,
VIDEO{
	vertical-align : bottom;
}

A {
	transition      : all $tt-std;
	text-decoration : none;
	&:hover {
	}
}

svg {
	transition : all $tt-std;
}

.lazy-image-container {
	position: relative;

	&.lazy-image-container--landscape {
		&:before {
			display: block;
			content: "";
			width: 100%;
			padding-top: (2 / 3) * 100%;
		}

		IMG, PICTURE {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}


.bgc {
	$self: &;
	--bgc: transparent;
	&--grey {
		--bgc: #{$color-grey-5}
	}

	&--mint {
		--bgc: #{$color-mint};
	}
}


.force-repaint {
	animation: repaint 2000ms;
	animation-iteration-count: infinite;
}

@keyframes repaint {
	from {
		width: 99.999%
	}
	to {
		width: 100%
	}
}

/** cookie banner ***/
div#CybotCookiebotDialog * {
        font-size: 12px !important;
}


@import "blocks/_blocks.scss";
@import "views/_views.scss";


