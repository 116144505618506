.tabs {
	$self: &;

	.theme-container--top & {
		--border-color: var(--color-theme-seperator-top, var(--color-mint));
		--indicator-color: var(--color-forrest);
	}
	.theme-container--bottom & {
		--border-color: var(--color-theme-seperator-bottom, var(--color-mint));
		--indicator-color: var(--color-forrest);
	}

	//margin: 0 auto;
	margin-bottom: 8rem;
	width: 100%;
	//min-width: var(--layout-min-width);
	//max-width: var(--layout-max-width);

	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: min-content min-content 0rem 1fr;
	grid-template-areas:
		'intro'
		'header'
		'indicator'
		'content'
	;

	.section-intro {
		grid-area: intro;
	}


	&__header {
		grid-area: header;
		border-bottom: 0.2rem solid var(--border-color);

		/* values / vars definied in grid.scss */
		//display: grid;
		//@include generate-inner-grid-template-columns(12);

		overflow: hidden;


	}

	&__header,
	&__indicator {
		margin: 0 auto;
		width: 100%;
		min-width: var(--layout-min-width);
		max-width: var(--layout-max-width);
	}

	&__nav,
	&__nav-item {
		margin:0;
		padding:0;
		list-style: none;
	}

	&__nav {
		//display: flex;
		//align-items: center;
		//justify-content: center;

		//grid-column: column-start 1 / column-end 11;
		//overflow: hidden;

		//overflow: visible;
	}

	&__nav-item {
		display: inline-block;
		white-space: nowrap;
		cursor: pointer;
		padding:0 2.5rem 1.5rem;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}



	}

	&__indicator {
		grid-area: indicator;
		position: relative;


	}
	&__indicator-line {
		--arrow-size: 0rem;

		transform: translateY(50%);
		position: absolute;
		bottom:0;
		width: 100%;
		height: 0.4rem;
		background: var(--indicator-color);

		&:after {
			//transition: all $tt-cta ease;
			content: '';
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: calc(0rem - var(--arrow-size));
			width: 0; height: 0;
			border-top: var(--arrow-size) solid var(--indicator-color);
			border-right: var(--arrow-size) solid transparent;
			border-left: var(--arrow-size) solid transparent;
		}
	}

	&__content {
		grid-area: content;
		overflow: hidden;
		//height: 0;
	}

	&__content-wrapper {
		//display: grid;
		//grid-auto-columns: 100%;
		//grid-auto-flow: column;
		//overflow: auto;

		display: flex;
		flex-wrap: nowrap;

		.layout--editmode & {
			display: grid;
		}
	
	}

	&__item {
		width: 100%;
		//width: 100vw;
		flex-shrink: 0;

		.grid__column {
			padding: 2rem 0;
			@include respond-to(tablet) {
				padding: 5rem 0;
			}
		}

		.layout--editmode & {
			padding-top: 5rem;
		}

		//grid-column: span 1;
	}

	//flickity overwrites

	.flickity-viewport {
		overflow: visible;
		transition: height 0.2s;
	}

}