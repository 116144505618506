.wysiwyg {
	$self: &;

	--bullet-color: var(--color-mint);

	.theme-container--top & {
		--bullet-color: var(--color-theme-seperator-top, var(--color-mint));
	}
	.theme-container--bottom & {
		--bullet-color: var(--color-theme-seperator-bottom, var(--color-mint));
	}

	//h1,h2,h3,
	//.headline {
	//	margin-top: 2em;
	//}

	P:first-child,
	.headline:first-child {
		margin-top: 0;
	}

	A[href] {
		color: var(--color-mint-darker);
		&:hover {
			color: var(--color-black);
		}
	}

	--size: 0.8rem;
	UL {
		list-style-type: none;

		margin: 0;
		padding: 0;



		LI {
			position: relative;
			padding-left: calc(var(--size) * 3);
			margin: 1.5rem 0;

			&:before {
				position: absolute;
				top:1rem;
				left: 0.2rem;
				content: ' ';
				background: var(--bullet-color);
				width: var(--size);
				height: var(--size);;
				display: inline-block;
				line-height: 2rem;
				margin-right: 0.5rem;
			}
		}
	}

	OL {


		margin: 0;
		padding: 0;

		LI {
			margin: 1.5rem 0;
			margin-left: 2.5rem;
			padding-left: calc(var(--size) * 0.5);
		}
	}
}