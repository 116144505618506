/**
 * breakpoints
 **/

@include add_breakpoint('phone',                        375px); //XXS
@include add_breakpoint('phone_landscape',              480px); //XS
@include add_breakpoint('tablet',                       768px); //S
@include add_breakpoint('tablet_landscape',             1024px); //M
@include add_breakpoint('display',                      1280px); //L
@include add_breakpoint('display_medium',               1440px); //L
@include add_breakpoint('display_large',                1600px); //XL
@include add_breakpoint('display_extralarge',           1920px); //XXL

@include add_breakpoint('until_phone',                  screen max-width 374px);
@include add_breakpoint('until_phone_landscape',        screen max-width 479px);
@include add_breakpoint('until_tablet',                 screen max-width 767px);
@include add_breakpoint('until_tablet_landscape',       screen max-width 1023px);
@include add_breakpoint('until_display',                screen max-width 1279px);
@include add_breakpoint('until_display_medium',         screen max-width 1440px);
@include add_breakpoint('until_display_large',         screen max-width 1600px);


@include add_breakpoint('after_tablet_landscape',       1025px);

@include add_breakpoint('min_height_tablet',                    screen min-height 1024px);
@include add_breakpoint('min_height_tablet_landscape',          screen min-height 768px);

@include add_breakpoint('min_height_after_tablet',                    screen min-height 1025px);
@include add_breakpoint('min_height_after_tablet_landscape',          screen min-height 769px);


/**
 * layout
 */




$layout-container-width: 147.6rem;
$layout-navigation-hamburger-breakpoint-until: until_display;
$layout-navigation-hamburger-breakpoint: display;


//susy3
/*
$layout-grid-config: (
	'columns': susy-repeat(35),
	'gutters': 0,
	'spread': 'narrow',
	'container-spread': 'narrow',
);*/

$layout-grid-config: (
	container: $layout-container-width,
	columns: 41,
	gutters: 0,
	gutter-position: after, //split,
	global-box-sizing: border-box,
	use-custom: (
		rem: true,
		clearfix: true
	),
	debug: (
		image: show,
		color: rgba(#66f, .25),
		output: overlay,
		toggle: top right,
	)
);
$susy: $layout-grid-config;


/**
 * views
 */


/**
 * colors
 */

$color-white: #fff;
$color-white-10: rgba($color-white, 0.1);
$color-white-20: rgba($color-white, 0.2);
$color-white-30: rgba($color-white, 0.3);
$color-white-50: rgba($color-white, 0.5);
$color-white-70: rgba($color-white, 0.7);

$color-black: #000;
$color-black-85: rgba($color-black, 0.85);
$color-black-70: rgba($color-black, 0.7);
$color-black-50: rgba($color-black, 0.5);
$color-black-30: rgba($color-black, 0.3);
$color-black-20: rgba($color-black, 0.2);
$color-black-10: rgba($color-black, 0.1);

$color-anthracite: rgba(45,44,45,1);
$color-aubergine: rgba(54,18,54,1);
$color-forrest: rgba(36,74,76, 1);
$color-mint: rgba(152,210,181,1);
$color-mint-new: rgba(118,187,153,1);
// michi adobe rgb $color-mint: rgba(175,210,184,1);
$color-mint-10: change_color($color-mint, $alpha: 0.1);
$color-mint-green: rgba(126,176,158,1);

$color-lime: rgba(222,232,142,1);
$color-coral: rgba(252,99,107,1);

//old
$color-lime-light: rgba(224,244,145,1);
$color-mint-dark: rgba(1,54,62,1);



$color-grey-1: $color-anthracite;
$color-grey-1-50: change_color($color-grey-1, $alpha: 0.5);
$color-grey-2: rgba(125,125,125,1);
$color-grey-2-65: change_color($color-grey-2, $alpha: 0.65);
$color-grey-3: rgba(180,180,180,1);
$color-grey-4: rgba(225,225,225,1);
$color-grey-5: rgba(244,244,244,1);





/**
 * z-order
 **/

$z-xhr-curtain: 5000;
$z-preloader: 5000;
$z-overlay: 2000;
$z-totop: 1100;
$z-header: 1000;
$z-menu: 990;
$z-triangle: 900;
$z-scroll-indicator: 900;
$z-above-lines: 60;
$z-lines: 50;
$z-below-lines: 40;

/**
 * transition times
 */

$tt-lazy: 500ms;
$tt-std: 200ms;
$tt-cta: 120ms;

/**
 * stuff
 */


/**
 * css theme vars
 */
BODY {
	//layout

	//--layout-triangle-height: 40rem;
	--layout-triangle-dimension: 22rem;

	--layout-triangle-multi: 0.45;
	--layout-triangle-top-modifier: calc(var(--layout-triangle-dimension) * var(--layout-triangle-multi)); //var(--layout-header-height);
	//--layout-triangle-top-modifier: calc(var(--layout-triangle-dimension) * var(--layout-triangle-multi)); //0.51; //0.62 -> column-start 2
	//--layout-triangle-height: 80vh;


	--layout-header-height: 8rem;

	--layout-section-spacing: 5rem;

	--layout-grid-gap: 0.8rem;
	--layout-grid-gap-half: calc(var(--layout-grid-gap) / 2); //0.4rem; //calc(var(--layout-grid-gap) / 2);
	--layout-grid-outer-column-width: 1fr;

	--layout-min-width: 0;
	--layout-max-width: 76.8rem;
	@include respond-to(tablet) {
		--layout-header-height: 12rem;
		--layout-min-width: 76.8rem;
		--layout-max-width: 128rem;

		--layout-grid-gap: 2rem;
		--layout-section-spacing: 10rem;
	}
	@include respond-to(tablet_landscape) {
		--layout-triangle-multi: 0.58;
	}
	@include respond-to(display) {
		--layout-min-width: 128rem;
		--layout-max-width: 192rem;

		--layout-grid-gap: 2.9rem;

		--layout-triangle-multi: 0.55;
	}


	//colors / theme

	--color-page-background: #{$color-white};
	--color-scroll-indicator: #{$color-mint};
	--color-lazy: #{$color-mint-10};

	--color-white: #{$color-white};
	--color-black: #{$color-black};
	--color-black-30: #{$color-black-30};
	--color-black-50: #{$color-black-50};

	--color-anthracite: #{$color-anthracite};
	--color-aubergine: #{$color-aubergine};
	--color-mint: #{$color-mint};
	--color-mint-darker: #{$color-mint-new};
	--color-mint-green: #{$color-mint-green};
	--color-forrest: #{$color-forrest};
	--color-lime: #{$color-lime};
	--color-coral: #{$color-coral};

	//old // deprecated
	--color-mint-dark: #{$color-forrest};
	--color-lime-light: #{$color-lime};


	--color-grey-1: #{$color-grey-1};
	--color-grey-1-50: #{$color-grey-1-50};
	--color-grey-2: #{$color-grey-2};
	--color-grey-2-65: #{$color-grey-2-65};
	--color-grey-3: #{$color-grey-3};
	--color-grey-4: #{$color-grey-4};
	--color-grey-5: #{$color-grey-5};

	--color-triangle: #{$color-black-85};


	--color-validation-success: var(--color-mint);
	--color-validation-error: var(--color-coral);

	--gradient-1: linear-gradient(313.26deg, #{$color-forrest} 0%, #{$color-mint} 100%);
	--gradient-2: linear-gradient(313.26deg, #{$color-mint} 0%, #{$color-lime} 100%);
	--gradient-3: linear-gradient(313.26deg, #{$color-black} 0%, #{$color-aubergine} 100%);


	/* possible themes:
	   white (defualt) ,white-green, white-grey, green-white, green
	 */

	--color-theme-lines: var(--color-mint);
	--color-theme-navi-border: var(--color-mint);

	--padding-theme-default: 12rem;
	--padding-theme-container-top: 0;
	--color-theme-container-top:                    var(--color-white);
	--color-theme-seperator-top:                    var(--color-mint);
	--color-theme-teaser-hover-top:                 var(--color-mint);
	--color-theme-button-mint-top:                  var(--color-mint);
	--color-theme-button-black-top:                 var(--color-black);
	--color-theme-button-grey-top:                  var(--color-grey-1);
	--color-theme-button-white-top:                 var(--color-white);


	--padding-theme-container-bottom: 0;
	--color-theme-container-bottom:                 var(--color-white);
	--color-theme-seperator-bottom:                 var(--color-mint);
	--color-theme-teaser-hover-bottom:              var(--color-mint);
	--color-theme-button-mint-bottom:               var(--color-mint);
	--color-theme-button-black-bottom:              var(--color-black);
	--color-theme-button-grey-bottom:               var(--color-grey-1);
	--color-theme-button-white-bottom:              var(--color-white);

	&.theme--white-triangle-green {
		--color-triangle: var(--color-mint);
	}

	&.theme--white-green {
		--color-theme-container-top:            var(--color-white);

		--padding-theme-container-bottom:       var(--padding-theme-default);
		--color-theme-container-bottom:         var(--color-mint);
		--color-theme-seperator-bottom:         var(--color-white);
		--color-theme-teaser-hover-bottom:      var(--color-forrest);

		--color-theme-button-mint-bottom:       var(--color-white);

		--color-scroll-indicator: var(--color-grey-3);
	}
	&.theme--white-grey {
		--color-theme-container-top:            var(--color-white);

		--padding-theme-container-bottom:       var(--padding-theme-default);
		--color-theme-container-bottom:         var(--color-grey-5);
	}
	&.theme--green-white {
		--color-theme-navi-border: var(--color-mint-green);

		--padding-theme-container-top:          var(--padding-theme-default);
		--color-theme-container-top:            var(--color-mint);
		--color-theme-seperator-top:            var(--color-white);
		--color-theme-teaser-hover-top:         var(--color-forrest);
		--color-theme-button-mint-top:          var(--color-white);

		--padding-theme-container-bottom:       var(--padding-theme-default);
		--color-theme-container-bottom:         var(--color-white);

		--color-scroll-indicator: var(--color-grey-3);
	}
	&.theme--green {
		--color-theme-navi-border: var(--color-mint-green);

		--color-theme-container-top:            var(--color-mint);
		--color-theme-seperator-top:            var(--color-white);
		--color-theme-teaser-hover-top:         var(--color-forrest);
		--color-theme-button-mint-top:          var(--color-white);

		--color-theme-container-bottom:         var(--color-mint);
		--color-theme-seperator-bottom:         var(--color-white);
		--color-theme-teaser-hover-bottom:      var(--color-forrest);
		--color-theme-button-mint-bottom:       var(--color-white);

		--color-scroll-indicator: var(--color-white);
	}
}