.footer {
	$self: &;

	background: var(--color-theme-container-bottom);
	grid-area: page-footer;
	grid-auto-rows: 22rem;
	color: var(--color-white);

	padding-top: 10rem;
	@include respond-to(tablet) {
		padding-top: 20rem;
	}


	&__image,
	&__content {
		position: relative;
	}

	&__image {
		height: 63.5rem;
		background: #eee;

		z-index: 5;
		grid-row-start: 1;

		.image,
		.image__wrapper {
			width: 100%;
			height: 100%;
		}

		PICTURE, IMG {
			width: 100%;
			height: 100%;
			object-fit: cover ;
			object-position: center;
		}
	}

	&__content {
		padding: 7rem 0 3.5rem;
		height: 100%;
		min-height: 50rem;
		background: var(--color-grey-1);

		z-index: 10;
		grid-row-start: 2;

		/* values / vars definied in grid.scss */
		display: grid;

		@include generate-inner-grid-template-columns(12);
		@include respond-to(tablet_landscape) {
			@include generate-inner-grid-template-columns(11);
		}

	}


	&__info,
	&__contact {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		display: grid;
		grid-template-columns: 1fr;
		@include respond-to(tablet_landscape) {
			grid-template-rows: 25% 60% 15%;
		}

		& > DIV {
			grid-column: 1/1;
			&:last-child {
				align-self: flex-end;

			}
		}
	}

	&__info,
	&__contact,
	&__newsletter {
		grid-column: column-start 1 / column-end 12;
		@include respond-to(tablet_landscape) {
			 grid-column: column-start 1 / column-end 3;
		}



		SPAN {

		}

		A,P {
			@include font-style-footer;
			color: var(--color-white);
		}

		A {
			&:hover {
				color: var(--color-mint);
			}
		}

		P,H3 {
			margin: 1.8rem 0;
		}
	}

	&__section {
		@include font-style-footer-small;
		text-transform: uppercase;
		color: var(--color-grey-2);
	}

	&__company-name {
		display: block;
		//line-height: 1;
		margin-bottom: 0.6em;

		SPAN {
			display: block;
			margin-top:-0.5rem;
		}
	}

	&__contact {
		@include respond-to(tablet_landscape) {
			grid-column: column-start 4 / column-end 6;
		}
	}

	&__newsletter {
		@include respond-to(tablet_landscape) {
			grid-column: column-start 7 / column-end 11;
		}

		P {
			@include font-style-footer-big;
		}

		FORM {
			margin-right: 10rem;
			margin-top: 4rem;
		}
	}

	&__meta {
		@include font-style-footer-small;

		&--copyright {
			@include respond-to(until_tablet) {
				width: 70%;
			}
		}
	}


}