.social-link,
.social-share {
	$self: &;

	--color: var(--color-grey-1);
	--color-hover: var(--color-black);
	--size: 4.2rem;

	display: flex;
	justify-content: space-between;
	margin: 2rem 0;

	&--footer {
		--color: var(--color-white);
		--size: 3.8rem;

		margin-top: 4rem;
		justify-content: flex-start;

	}

	&--text {
		display: block;
		margin-top: 0;

	}

	&__item {
		transition: all $tt-cta ease;

		display: flex;
		align-items: center;
		justify-content: center;

		width: var(--size);
		height: var(--size);

		border: 0.2rem solid var(--color);


		svg {
			width: 60%;
			height: 60%;
			fill: var(--color);
		}

		&:hover {
			border-color: var(--color-hover);
			background: var(--color-hover);
			svg {
				fill: var(--color-white);
			}
		}

		#{ $self }--footer & {
			--color-hover: var(--color-mint);
			margin-right: 2rem;
			&:last-child {
				margin-right: 0;
			}

		}

		#{ $self }--text & {
			border: 0;
			border-bottom: 0.2rem solid transparent;
			width: auto;
			height: auto;
			display: inline-block;



			color: var(--color);
			background: transparent;

			&:hover {
				border-color: var(--color-hover);
				color: var(--color-hover);
			}
		}

	}
}