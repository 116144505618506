.person {
	$self: &;
	@include font-style-body-small;

	--color: var(--color-black);
	--person-image-size: 17.5rem;

	display: grid;
	grid-column-gap: 3rem;
	grid-row-gap: 3rem;

	grid-template-columns: auto;
	grid-template-rows: auto auto;

	@include respond-to(tablet) {
		grid-template-columns: var(--person-image-size) 1fr;
		grid-template-rows: auto auto;
	}

	&__image {
		justify-self: flex-end;

		grid-column: 1;
		grid-row: 1;

		width: var(--person-image-size);
		height: var(--person-image-size);
		background: var(--color-mint);
		clip-path: polygon(0% 0%, 0% 100%, 0.5rem 100%, 2.5rem calc(100% - 2rem), 100% calc(100% - 2rem), 100% 0%);

		IMG, PICTURE {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

	}


	&__content {
		grid-row: 2;

		@include respond-to(tablet) {
			grid-column: 2;
			grid-row: 1;
		}

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		grid-row-gap: 2.5rem;

	}

	&__desc {
		grid-row: 3;
		@include respond-to(tablet) {
			grid-row: 2;
			grid-column: span 2;
		}

	}

	&__meta {
		SPAN {
			&:first-child {
				border-bottom: 0.2rem solid var(--color);
			}
		}
	}
	&__controls {}
}

.person-vcard {
	--person-image-size: 15.5rem;

	width: var(--person-image-size);
	height: var(--person-image-size);

	IMG,PICTURE {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

}