.theme-container {
	$self: &;

	&--space {
		margin-top:var(--layout-header-height);
	}

	&--top {
		background: var(--color-theme-container-top, transparent);
		padding-bottom: var(--padding-theme-container-top);
	}

	&--bottom {
		background: var(--color-theme-container-bottom, transparent);
		padding-top: var(--padding-theme-container-bottom);

		.seperator:first-child {
			padding-top:0;
		}
	}
}