

.menu {
	$self: &;

	transform: translate3d(0, 0, 0);

	display: none;

	position: fixed;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	z-index: $z-menu;

	background: var(--color-aubergine);

	--screen-height: 600px;
	--element-width: 10%;

	--initial-distance-to-left: calc(-250% + var(--element-width) - 5rem);
	--element-distance-to-left: var(--initial-distance-to-left);


	//edge < 76 test


	@supports ((-webkit-clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%)) or (clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%))) {

		//display: block;
		clip-path: polygon(
				calc(var(--element-distance-to-left) + var(--screen-height)) 0%,
				calc(var(--element-distance-to-left) + var(--element-width) + var(--screen-height)) 0%,
				calc(var(--element-distance-to-left) + var(--element-width)) 100%,
				var(--element-distance-to-left) 100%
		);

		-webkit-clip-path: polygon(
				calc(var(--element-distance-to-left) + var(--screen-height)) 0%,
				calc(var(--element-distance-to-left) + var(--element-width) + var(--screen-height)) 0%,
				calc(var(--element-distance-to-left) + var(--element-width)) 100%,
				var(--element-distance-to-left) 100%
		);
	}


	&__content {
		position: absolute;
		top:0;
		left:0;
		z-index: 10;

		width: 100%;
		height: 100%;

		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr min-content min-content;

		grid-template-areas:
			'top'
			'center'
			'bottom'
		;
	}

	&__top {
		grid-area: top;
		padding-top: var(--layout-header-height);
		align-self: center;
	}

	&__center,
	&__bottom {
		justify-self: center;
		align-self: center;
	}

	&__center {
		grid-area: center;
	}
	&__bottom {
		grid-area: bottom;
		padding: 2rem 0;
	}
}