.seperator {
	$self: &;

	.theme-container--top & {
		--seperator-color: var(--color-theme-seperator-top, var(--color-mint));
	}
	.theme-container--bottom & {
		--seperator-color: var(--color-theme-seperator-bottom, var(--color-mint));
	}


	display: flex;
	align-items: center;
	justify-content: center;

	padding: 8rem 0 12rem;

	&--line {
		padding: 8rem 0 !important;
	}

	&__line {
		transform: rotate(45deg);
		transform-origin: center;
		width: 0.4rem;
		height: 10rem;
		background: var(--seperator-color);

		#{$self}--line & {
			transform: rotate(0);
			width: 100%;
			max-width: var(--layout-max-width);
			height: 0.4rem;
		}
	}
}