.accordion {
	$self: &;

	.theme-container--top & {
		--border-color: var(--color-theme-seperator-top, var(--color-mint));
	}
	.theme-container--bottom & {
		--border-color: var(--color-theme-seperator-bottom, var(--color-mint));
	}

	&__item {
		border-top: 0.2rem solid var(--border-color);
		border-bottom: 0.2rem solid var(--border-color);

		& + & {
			border-top: 0;
		}

		&--open {

		}

	}

	&__header {
		height: 10rem;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	&__content {
		overflow: hidden;
		display: grid;

		.layout--editmode & {
			height: auto;
		}

		.layout--livemode & {
			display: none;
		}

		@include generate-inner-grid-template-columns-only-column(11);
		@include respond-to(display) {
			@include generate-inner-grid-template-columns-only-column(7);
		}

	}

	&__column {

		.layout--editmode & {
			min-height: 10rem;
		}

		margin-bottom: 3rem;

		grid-column: column-start 1 / column-end 12;

		@include respond-to(tablet_landscape) {
			grid-column: column-start 1 / column-end 5;
			&:last-child {
				grid-column: column-start 6 / column-end 11;
			}
		}

		@include respond-to(display) {
			grid-column: column-start 1 / column-end 3;
			&:last-child {
				grid-column: column-start 4 / column-end 8;
			}
		}
	}
}
