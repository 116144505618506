.picturerow {
	$self : &;

	.grid__column {

		&:nth-child(2n) {
			margin-top: 30rem;
		}

		&:nth-child(3n) {
			margin-top: 10rem;
		}
	}

}