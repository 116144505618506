.tooltip {
	$arrowSize: 1rem;
	$height: 3.1rem;
	$spacing: 0.8rem;

	--background-color: var(--color-validation-success);

	transition: all $tt-cta ease;
	transform: translateY(#{ 0 - $height - $arrowSize - $spacing - ($height / 1.5)});
	position: absolute;
	top:0;
	z-index: 100;
	opacity: 0;
	pointer-events: none;

	width: 100%;
	height: $height;

	color: var(--color-white);
	background: var(--background-color);
	border-radius: 0.2rem;

	text-align: center;

	&:after {
		content: '';
		position: absolute;
		top: 99%;
		right: 2.2rem;
		//margin-left: -8px;
		width: 0; height: 0;
		border-top: $arrowSize solid var(--background-color);
		border-right: $arrowSize solid transparent;
		border-left: $arrowSize solid transparent;
	}


	&--half {
		width: 50%;
		left: 50%;
	}

	@at-root .form__group--has-error &,
	&--visible {
		--background-color: var(--color-validation-error);
		opacity: 1;
		transform: translateY(#{ 0 - $height - $arrowSize - $spacing});
	}


}