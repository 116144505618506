

/** mixins **/


@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

@mixin font-size($font-size, $line-height: FALSE, $letter-spacing: FALSE, $double: FALSE) {

	@if $double != FALSE {
		$font-size: $font-size * 2;
		$line-height: $line-height * 2;
	}

	font-size: calc(#{$font-size + 'rem'} * var(--font-size-multi, 1));

	@if $line-height != FALSE {
		line-height: ($line-height / ($font-size / 100)) / 100;
	}
	@else {
		line-height: 1;
	}

	@if $letter-spacing != FALSE {
		//letter-spacing: $letter-spacing / 1000 + rem; // PhotoshopValue / 1000 = LetterSpacingEM
		//letter-spacing: $letter-spacing / 1000 + em; // PhotoshopValue / 1000 = LetterSpacingEM

		//sketch
		letter-spacing: $letter-spacing / 10 + rem;

		//letter-spacing: $letter-spacing * ($letter-spacing * 10) / 1000 + px; // PhotpshopValue * PixelFontSize / 1000 = LetterSpacingPX
	}
}


///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
	$responsive-unitless: $responsive / ($responsive - $responsive + 1);
	$dimension: if(unit($responsive) == 'vh', 'height', 'width');
	$min-breakpoint: $min / $responsive-unitless * 100;

	@media (max-#{$dimension}: #{$min-breakpoint}) {
		font-size: $min;
	}

	@if $max {
		$max-breakpoint: $max / $responsive-unitless * 100;

		@media (min-#{$dimension}: #{$max-breakpoint}) {
			font-size: $max;
		}
	}

	@if $fallback {
		font-size: $fallback;
	}

	font-size: $responsive;
}

//@include aspect-ratio(16, 9);
@mixin aspect-ratio($width, $height) {
	position: relative;
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin variable($property, $variable, $fallback) {
	#{$property}: $fallback;
	#{$property}: var($variable);
}

@mixin build-colors($colorStore) {
	@each $colorId, $colorData in $colorStore {

		$color : map_get($colorData, color);
		$fontColor : null;
		@if map_get($colorData, mode) == 'light' {
			$fontColor : $color-white;
		} @else {
			$fontColor : $color-black;
		}

		$old-color: $g-color;
		$old-font-color: $g-font-color;

		$g-color: $color !global;
		$g-font-color: $fontColor !global;

		&.#{map_get($colorData, ident)} {
			@content;
		}

		$g-color: $old-color !global;
		$g-font-color: $old-font-color !global;
	}
}

@mixin clearfix {
	&::after {
		clear: both;
		content: "";
		display: table;
	}
}


@mixin grid-template() {
	grid-template-columns:
		[outer-column] var(--layout-grid-outer-column-width)
		[outer-column-end column-space] var(--layout-grid-gap-half)
		[column-line] 0.1rem [column-line-end]
		repeat(11,
			[column-start] 1fr
			[column-end column-space] var(--layout-grid-gap-half)
			[column-line] 0.1rem
			[column-line-end column-space] var(--layout-grid-gap-half))
		[column-start] 1fr
		[column-end column-line]  0.1rem
		[column-line-end column-space] var(--layout-grid-gap-half)
		[outer-column] var(--layout-grid-outer-column-width) [outer-column-end];
}

@mixin generate-inner-grid-template-columns($repeat: 11, $outerColumnVarName:'layout-grid-outer-column-width', $gapHalfVarName: 'layout-grid-gap-half') {
	grid-template-columns:
		[outer-column] var(--#{$outerColumnVarName})
		[outer-column-end column-space] var(--#{$gapHalfVarName})
		[column-line] 0.1rem [column-line-end]
		repeat($repeat,
			[column-start] 1fr
			[column-end column-space] var(--#{$gapHalfVarName})
			[column-line] 0.1rem
			[column-line-end column-space] var(--#{$gapHalfVarName}))
		[column-start] 1fr [column-end];
}

@mixin generate-inner-grid-template-columns-only-column($repeat: 11, $outerColumnVarName:'layout-grid-outer-column-width', $gapHalfVarName: 'layout-grid-gap-half') {
	grid-template-columns:
		repeat($repeat,
			[column-start] 1fr
			[column-end column-space] var(--#{$gapHalfVarName})
			[column-line] 0.1rem
			[column-line-end column-space] var(--#{$gapHalfVarName}))
		[column-start] 1fr [column-end];
}