.jobs {
	$self: &;

	margin-top: 5rem !important;

	&__container {
		padding: 8rem 0;
		background: var(--color-grey-5);
	}

	&__grid {
		margin-top: 3rem;
		@include respond-to(tablet) {
			margin-top: 6rem;
		}
	}

	&__filters {
		background: var(--color-grey-5);
		z-index: 70;
		padding-top: 5rem;
		padding-bottom: 3rem;

		display: none;
		@include respond-to(tablet) {
			display: grid;
		}
	}

	&__filters-select {

	}
	&__filters-tags {
		margin-top: 2rem;
	}
}