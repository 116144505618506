.page-controls {
	$self: &;
	//display: none;
	&__controls {
		margin-bottom: 4.5rem;

		#{$self}--blog & {
			display: flex;
			align-items: center;
		}
	}

	&__meta {
		position: relative;
		margin-bottom: 6.5rem;
		&:after {
			transform: translateY(1.3rem);
			position: absolute;
			bottom:0;
			left:0;

			content: ' ';
			width: 3rem;
			height: 0.4rem;
			background: var(--color-grey-2);

		}
	}

	&__social {
		display: none;
	}

	&__button-group {
		display: flex;
		align-items: center;

		#{$self}--blog & {
			margin-left: auto;

			.button:last-child {
				@include respond-to(until_tablet) {
					margin-left: 2rem;
				}
			}

		}
	}
}