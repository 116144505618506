.area {
	$self: &;

	& + & {
		margin-top: 3rem;
	}
}

@import "wysiwyg.scss";
@import "headline.scss";
@import "picturerow.scss";
@import "image.scss";
@import "video.scss";
@import "seperator.scss";
@import "tabs.scss";
@import "quote.scss";
@import "timeline.scss";
@import "page-intro.scss";
@import "accordion.scss";
@import "numbers.scss";