/**
 * cases:
 * 1) Page has dark triangle, logo is white until header collappse
 *
 */


.header {
	$self: &;

	--header-color: var(--color-black);
	--header-logo-color: var(--header-color);
	--header-background-color: transparent; //var(--color-white);

	.layout--default-small-header &,
	.layout--has-triangle & {
		--header-color: var(--color-white);
	}

	.layout--contact &,
	.layout--editmode & {
		--header-color: var(--color-black);
	}

	transition: all $tt-std ease-in-out;
	position: fixed;
	top:0;
	left:0;
	z-index: $z-header;

	.layout--editmode & {
		position: static;
		transform: none;
	}

	background: var(--header-background-color);
	color: var(--header-color);
	pointer-events: none;






	//default
	&--colored {
		--header-background-color: var(--color-black-50);
		--header-color: var(--color-white);
	}

	&--transparent {

	}

	&--dark {

	}

	&--light {

	}

	////body modiffier
	//@at-root .has--triangle & {
	//	--header-logo-color: var(--color-white);
	//
	//	&#{$self}--collapsed {
	//		--header-logo-color: var(--header-color);
	//	}
	//}




	//out of view
	&--collapsed {
		transform: translateY(-100%);


	}


	&__column {
		height: var(--layout-header-height);
		display: flex;
		align-items: center;
		justify-content: space-between;
		pointer-events: all;


	}

	&__logo {
		display: block;
		width: 14rem;
		height: auto;

		@include respond-to(tablet) {
			width: 22rem;
		}

		@include respond-to(display) {
			width: 25rem;
		}

		svg {
			//--header-logo-color: #fff;
			fill: var(--header-logo-color);
		}
	}

	$x: display_large;

	&__tools {
		display: none;
		@include respond-to($layout-navigation-hamburger-breakpoint) {
			display: flex;
			align-items: center;
		}
	}

	&__hamburger {
		display: block;
		@include respond-to($layout-navigation-hamburger-breakpoint) {
			display: none;
		}
	}


	//display: grid;
	//grid-template-rows: 10rem;
	//grid-template-columns: var(--global-layout-grid-template-columns);
	//
	//&__container {
	//	grid-column: 2/2;
	//	grid-row: 1/1;
	//
	//	display: flex;
	//	align-items: center;
	//	justify-content: space-between;
	//}

}