.intro {
	$self: &;

	//margin: 10rem 0;

	&--big {
		margin: 0;
		#{$self}__heading {
			@include font-style-intro-big;
			margin:0;
		}
	}

	&__heading {
		@include font-style-intro;
		margin-top:0;

		&--big {
			@include font-style-intro-big;
		}

		.theme--white-triangle-green & {
			color: var(--color-mint);
		}
	}

	&__content {
		@include font-style-body-lead;
	}
}