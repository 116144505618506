.headline {
	$self : &;

	//display: inline-flex;
	//align-items: center;

	&:first-child {
		//margin-top:0;
	}

	&--1 {
		@include font-style-headline-1
	}

	&--2 {
		@include font-style-headline-2
	}

	&--3 {
		@include font-style-headline-3
	}

	&--4 {
		@include font-style-headline-4
	}

	&--5 {
		@include font-style-headline-5;
	}

	&--6 {
		@include font-style-headline-6;
	}

	&--seperator,
	&--seperator-big {
		@include font-style-headline-seperator;
		text-transform: uppercase;
		position: relative;
		margin-bottom: calc(1em + 1.5rem);

		&:after {
			transform: translateY(1.5rem);
			content: ' ';
			position: absolute;
			bottom:0;
			left:0;

			width: 3rem;
			height: 0.4rem;
			background: var(--color-grey-2);
		}
	}

	&--seperator-big {
		@include font-style-headline-seperator-big;
	}
}