.intro-slider {
	$self: &;

	&.preloading {
		opacity: 0;
		visibility: hidden;
	}

	--media-height: 700;

	.layout--index & {
		--media-height: 950;
	}

	&--small {
		--media-height: 475;
	}

	--element-height: 600px;
	--element-width: 320%;
	--element-distance-to-left: -220%;

	//z-index: $z-above-lines;

	@include respond-to(tablet_landscape) {
		--element-width: 150%;
		--element-distance-to-left: -50%;
	}

	position: relative;


	width: 100%;
	min-width: var(--layout-min-width);
	max-width: var(--layout-max-width);
	height: 100vh;
	margin: 0 auto;

	overflow: hidden;


	@include respond-to(tablet_landscape) {
		height: auto;

		&:before {
			display: block;
			content: "";
			width: 100%;
			//padding-top: (1133 / 1920) * 100%;
			//padding-top: (950 / 1920) * 100%;
			padding-top: calc(var(--media-height) / 1920 * 100%);

		}
	}

	&__hover {
		position: absolute;
		left: 0;
		//top:var(--layout-header-height);
		top:0;

		z-index: $z-triangle + 1;
		cursor: pointer;


		height: 100%;
		width: 12.5%;

		&[data-direction="next"] {
			left: auto;
			right: 0;
		}

		.layout--editmode &,
		[data-disabled="slider"] & {
			display: none;
		}

		.button {
			opacity: 0;
			pointer-events: none;
		}

		&:hover {
			button {
				opacity: 1;
				pointer-events: all;
			}
		}

		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__pager {
		position: absolute;
		top: var(--layout-header-height);
		left: 10rem;
		z-index: 6666;
	}
	&__pager-number {
		@include font-bold;
		color: var(--color-white);

		display: flex;
		align-items: flex-end;
		width: 8rem;
		padding-left: 1.2rem;
		padding-bottom: 0.2rem;
		height: 5rem;
		&--summary {
			align-items: flex-start;
			justify-content: flex-end;
			padding-left: 0;
			padding-bottom: 0;
			padding-top: 0.2rem;
			padding-right: 2.2rem;

		}
	}
	&__pager-divider {

		position: absolute;
		top: 54%;
		left: 28%;
		transform-origin: center;
		transform: skew(-45deg) translateY(-50%);
		width: 0.4rem;
		height: 2rem;
		background: var(--color-white);

	}


	&__items,
	&__item,
	&__content,
	&__media {
		z-index: $z-above-lines;         

		height: 100%;
		width: 100%;
	}

	&__items,
	&__item {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

	}

	&__item {


		display: block;
		z-index: 0;


		&#{$self}--active  {
			display: block;
			z-index: 10;

			//clip-path: polygon(
			//		calc(var(--element-distance-to-left) + var(--element-height)) 0%,
			//		calc(var(--element-distance-to-left) + var(--element-width) + var(--element-height)) 0%,
			//		calc(var(--element-distance-to-left) + var(--element-width)) 100%,
			//		var(--element-distance-to-left) 100%
			//);

		}
	}

	&__content,
	&__media {
		position: absolute;
		top:0;
		left:0;
		z-index: 5;
	}

	&__content {
		z-index: 10;
		display: grid;
		grid-auto-rows: min-content;
		@include grid-template();

		align-content: flex-end;
		padding-bottom: 10vh;

		--type-modifier: 0rem;

		#{ $self }--content-small & {
			--type-modifier: 4rem;
		}

		@include respond-to(tablet_landscape) {
			align-content: center;
			.layout--has-triangle & {
				padding-top: calc(var(--layout-triangle-top-modifier) + var(--type-modifier));
			}
		}

		#{ $self }--small &,
		.layout--editmode & {
			align-content: flex-end;
		}

	}

	&__text,
	&__buttons {
		grid-column: column-start 1 / column-end 12;
		@include respond-to(tablet) {
			grid-column: column-start 2 / column-end 12;
		}
		@include respond-to(display_medium) {
			grid-column: column-start 2 / column-end 9;
		}
		@include respond-to(display_large) {
			grid-column: column-start 2 / column-end 7;
		}

	}

	&__buttons {
		margin-top: 4rem;
	}

	&__title,
	&__tagline,
	&__subline {

		.layout--livemode & {
			display: block;
			user-select: none;
			pointer-events: none;
		}

	}

	&__title {
		@include font-style-intro-slider-big;

		color: var(--color-mint);

		#{ $self }--content-small & {
			@include font-style-intro;
			color: var(--color-white);
		}

		#{ $self }--small & {
			@include font-size-intro-small;
		}
	}

	&__tagline {
		@include font-style-intro-tagline;
		color: var(--color-mint);
		text-transform: uppercase;
		margin-bottom: 0.7rem;

		#{ $self }--content-small & {
			margin:0;
		}
	}

	&__subline {
		@include font-style-intro-subline;
		color: var(--color-mint);
		margin-top: 1.7rem;
	}

	&__media {
		width: 100%;
		height: 100%;

		.video,
		.video__wrapper,
		.image,
		.image__wrapper {
			width: 100%;
			height: 100%;
		}

		#{ $self }--video &,
		#{ $self }--image & {
			.image__image,
			.video__video,
			IMG, PICTURE {
				object-fit: cover;
				object-position: center;
				width: 100%;
				height: 100%;
			}
		}
	}
}