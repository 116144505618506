.teaser-list {
	$self: &;

	&__container {
		align-items: center;
		margin-bottom: 12.6rem;
	}

	&__subline {
		@include font-style-body-mini;
		background: var(--color-mint);
		color: var(--color-white);
		text-transform: uppercase;
		padding: 0.2rem 0.8rem;

	}

	&__content {
		H3,
		.headline {
			margin-top: 0.8em;
		}
	}
}