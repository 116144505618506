/**
 * 100: Ultra Light
 * 200: Thin
 * 300: Light
 * 400: Regular
 * 500: Semi Bold
 * 600: Bold
 * 700: Extra Bold
 * 800: Heavy
 * 900: Ultra Heavy
 */

$font-weight-light:             300;
$font-weight-regular:           400;
$font-weight-semi-bold:         500;
$font-weight-bold:              700;
$font-weight-extra-bold:        900;


$font-version: 0.1;
$font-path: '../../fonts/';
//fonts: (
//	Realtext-0:             (name: 'Realtext-Light',                file: 'realtextpro/3A3B10_0_0',    font-weight: $font-weight-regular, font-style: normal), //light
//	Realtext-1:             (name: 'Realtext-LightItalic',          file: 'realtextpro/3A3B10_1_0',    font-weight: $font-weight-regular, font-style: normal), //light italic
//	Realtext-2:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_2_0',    font-weight: $font-weight-regular, font-style: normal), //regular
//	Realtext-3:             (name: 'Realtext-Italic',               file: 'realtextpro/3A3B10_3_0',    font-weight: $font-weight-regular, font-style: normal), //regular italic
//	Realtext-4:             (name: 'Realtext-Bold',                 file: 'realtextpro/3A3B10_4_0',    font-weight: $font-weight-regular, font-style: normal), //bold
//	Realtext-5:             (name: 'Realtext-BoldItalic',           file: 'realtextpro/3A3B10_5_0',    font-weight: $font-weight-regular, font-style: normal)  //bold italic
//);

$fonts: (
	Realtext-0:             (name: 'RealtextLight',                         file: 'realtextpro/3A3B10_0_0',    font-weight: $font-weight-regular, font-style: normal), //light
	Realtext-1:             (name: 'RealtextLight',                         file: 'realtextpro/3A3B10_1_0',    font-weight: $font-weight-regular, font-style: italic), //light italic
	Realtext-2:             (name: 'Realtext',                              file: 'realtextpro/3A3B10_2_0',    font-weight: $font-weight-regular, font-style: normal), //regular
	Realtext-3:             (name: 'Realtext',                              file: 'realtextpro/3A3B10_3_0',    font-weight: $font-weight-regular, font-style: italic), //regular italic
	Realtext-4:             (name: 'RealtextBold',                          file: 'realtextpro/3A3B10_4_0',    font-weight: $font-weight-regular, font-style: normal), //bold
	Realtext-5:             (name: 'RealtextBold',                          file: 'realtextpro/3A3B10_5_0',    font-weight: $font-weight-regular, font-style: italic), //bold italic

	Realtext-6:             (name: 'RealtextDemi',                          file: 'ffrealtextpro/FFRealTextDemibold/font',    font-weight: $font-weight-regular, font-style: normal), //demibold
	Realtext-7:             (name: 'RealtextDemi',                          file: 'ffrealtextpro/FFRealTextDemiboldItalic/font',    font-weight: $font-weight-regular, font-style: italic), //demibold italic
	Realtext-8:             (name: 'RealtextBook',                          file: 'ffrealtextpro/FFRealTextBook/font',    font-weight: $font-weight-regular, font-style: normal), //book
	Realtext-9:             (name: 'RealtextBook',                          file: 'ffrealtextpro/FFRealTextBookItalic/font',    font-weight: $font-weight-regular, font-style: italic), //book italic
	Realtext-10:            (name: 'RealtextMedium',                          file: 'ffrealtextpro/FFRealTextMedium/font',    font-weight: $font-weight-regular, font-style: normal), //medium
	Realtext-11:            (name: 'RealtextMedium',                          file: 'ffrealtextpro/FFRealTextMediumItalic/font',    font-weight: $font-weight-regular, font-style: italic), //medium italic
	Realtext-12:            (name: 'RealtextSemiLight',                          file: 'ffrealtextpro/FFRealTextSemilight/font',    font-weight: $font-weight-regular, font-style: normal), //medium
	Realtext-13:            (name: 'RealtextSemiLight',                          file: 'ffrealtextpro/FFRealTextSemilightItalic/font',    font-weight: $font-weight-regular, font-style: italic), //medium italic
);


//$fonts: (
//	Realtext-0:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_0_0',    font-weight: $font-weight-light, font-style: normal), //light
//	Realtext-1:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_1_0',    font-weight: $font-weight-light, font-style: italic), //light italic
//	Realtext-2:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_2_0',    font-weight: $font-weight-regular, font-style: normal), //regular
//	Realtext-3:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_3_0',    font-weight: $font-weight-regular, font-style: italic), //regular italic
//	Realtext-4:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_4_0',    font-weight: $font-weight-bold, font-style: normal), //bold
//	Realtext-5:             (name: 'Realtext',                      file: 'realtextpro/3A3B10_5_0',    font-weight: $font-weight-bold, font-style: italic)  //bold italic
//);

@each $fontId, $fontData in $fonts {
	@font-face {
		font-family: map_get($fontData, name);
		src:    url($font-path + map_get($fontData, file) + '.woff2') format('woff2');
		//url($font-path + map_get($fontData, file) + '.woff' + '?' + $font-version) format('woff');

		font-weight: map_get($fontData, font-weight);
		font-style:  map_get($fontData, font-style);
		font-display: swap;
	}
}

$font-sans-light: 'RealtextLight', sans-serif;
$font-sans-semi-light: 'RealtextSemiLight', sans-serif;
$font-sans: 'Realtext', sans-serif;
$font-sans-bold: 'RealtextBold', sans-serif;
$font-sans-book: 'RealtextBook', sans-serif;
$font-sans-demi: 'RealtextDemi', sans-serif;
$font-sans-medium: 'RealtextMedium', sans-serif;


//$font-sans: 'Realtext', sans-serif;
//$font-sans-italic: 'Realtext-Italic', sans-serif;
//$font-sans-light: 'Realtext-Light', sans-serif;
//$font-sans-light-italic: 'Realtext-LightItalic', sans-serif;
//$font-sans-bold: 'Realtext-Bold', sans-serif;
//$font-sans-bolditalic: 'Realtext-BoldItalic', sans-serif;



@mixin font-light() {
	font-family: $font-sans;
	font-weight: $font-weight-regular;
	//font-family: $font-sans;
	//font-weight: $font-weight-light;
}

@mixin font-semi-light() {
	font-family: $font-sans-semi-light;
	font-weight: $font-weight-regular;
}

@mixin font-reg() {
	font-family: $font-sans;
	font-weight: $font-weight-regular;
}
@mixin font-bold() {
	font-family: $font-sans-bold;
	font-weight: $font-weight-regular;
}

@mixin font-book() {
	font-family: $font-sans-book;
	font-weight: $font-weight-regular;
}
@mixin font-demi() {
	font-family: $font-sans-demi;
	font-weight: $font-weight-regular;
}
@mixin font-medium() {
	font-family: $font-sans-medium;
	font-weight: $font-weight-regular;
}

/**
 * FONT SIZE MIXINS
 **/

$fontTabletBreakpoint: tablet_landscape; //tablet

@mixin font-size-intro() {
	@include font-size(5, 5.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(5.8, 6.4);
	}
}
@mixin font-size-intro-big() {
	@include font-size(5, 5.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(6.8);
	}
}
@mixin font-size-intro-slider-big() {
	@include font-size(5, 5.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(9.8);
	}
}
@mixin font-size-intro-small() {
	@include font-size(4, 4.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(5.5, 6);
	}
}


@mixin font-size-h1() {
	@include font-size(4, 4.8);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(5.5, 6);
	}
}
@mixin font-size-h2() {
	@include font-size(3.2, 3.8);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(4, 4.8);
	}
}
@mixin font-size-h3() {
	@include font-size(2.4, 2.8);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(2.8, 3.6);
	}
}
@mixin font-size-h4() {
	@include font-size(1.8, 2.2);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(2, 2.6);
	}
}
@mixin font-size-h5() {
	@include font-size(1.6, 2.2);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(1.8, 2.2);
	}
}
@mixin font-size-h6() {
	@include font-size(1.4, 1.8);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(1.6, 2.2);
	}
}
@mixin font-size-headline-seperator() {
	@include font-size(1.2, 2.2, 2);
}



@mixin font-size-body-lead() {
	@include font-size(1.8, 2.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(2.5, 3.2);
	}
}

@mixin font-size-body() {
	@include font-size(1.6, 2.4);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(2.0, 2.9, -0.05);
	}
}

@mixin font-size-body-small() {
	@include font-size(1.4, 1.8);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(1.7, 2.5);
	}
}

@mixin font-size-body-mini() {
	@include font-size(1.2, 1.8);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(1.4, 2);
	}
}


@mixin font-size-footer-big() {
	@include font-size(2, 2.8);
}
@mixin font-size-footer() {
	@include font-size(1.6, 3);
}
@mixin font-size-footer-small() {
	@include font-size(1.4, 3.2);
	@include respond-to(display) {
		@include font-size(1.2, 1.8);
	}
}

@mixin font-size-button() {
	@include font-size(1.6, 2.2);
}
@mixin font-size-button-small() {
	@include font-size(1.4, 2);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(1.6, 2.2);
	}
}
@mixin font-size-button-big() {
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(2.6, 3);
	}
}

@mixin font-size-qoute-text() {
	@include font-size(2, 2.8);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(3, 3.8);
	}
	@include respond-to(display) {
		@include font-size(5.5, 6.4);
	}
}

@mixin font-size-image-caption() {
	@include font-size(1.6, 2.2);
}

@mixin font-size-form() {
	@include font-size(1.6, 2.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(1.6, 2.6); //2.6
	}

}

@mixin font-size-form-big() {
	@include font-size(1.6, 2.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(2.6, 3.6) //, 3.6);
	}
}

@mixin font-size-navigation() {
	@include font-size(4, 6);

	@include respond-to($layout-navigation-hamburger-breakpoint) {
		@include font-size(1.7, 2.5);
	}

	@include respond-to(display_medium) {
		@include font-size(1.9, 2.5);
	}
}
@mixin font-size-navigation-small() {
	@include font-size(2.4, 4);
	@include respond-to($layout-navigation-hamburger-breakpoint) {
		@include font-size(1.4, 2.4);
	}
}

@mixin font-size-numbers() {
	@include font-size(5, 5.6);
	@include respond-to($fontTabletBreakpoint) {
		@include font-size(8, 9.6);
	}
	@include respond-to(display) {
		@include font-size(5, 5.6);
	}
	@include respond-to(display_large) {
		@include font-size(8, 9.6);
	}
}

@mixin font-size-es-subline() {
	@include font-size(3.2, 3.8);
}

/**
 * FONT STYLE MIXINS
 */
@mixin font-style-intro() {
	@include font-bold;
	@include font-size-intro;
}
@mixin font-style-intro-big() {
	@include font-bold;
	@include font-size-intro-big;
}
@mixin font-style-intro-slider-big() {
	@include font-bold;
	@include font-size-intro-slider-big;
}
@mixin font-style-intro-tagline() {
	@include font-reg;
	@include font-size-body-mini;
	letter-spacing: 0.2rem;
}
@mixin font-style-intro-subline() {
	@include font-light;
	@include font-size-body-small;
}

@mixin font-style-headline-1() {
	@include font-bold;
	@include font-size-h1;
}
@mixin font-style-headline-2() {
	@include font-bold;
	@include font-size-h2;
}
@mixin font-style-headline-3() {
	@include font-bold;
	@include font-size-h3;
}
@mixin font-style-headline-4() {
	@include font-bold;
	@include font-size-h4;
}
@mixin font-style-headline-5() {
	@include font-bold;
	@include font-size-h5;
}
@mixin font-style-headline-6() {
	@include font-bold;
	@include font-size-h6;
}
@mixin font-style-headline-seperator() {
	@include font-reg;
	@include font-size-headline-seperator;
}
@mixin font-style-headline-seperator-big() {
	@include font-bold;
	@include font-size-h3;
	@include font-size-headline-seperator;
}


@mixin font-style-body-lead() {
	@include font-semi-light;
	@include font-size-body-lead;
}

@mixin font-style-body-lead-book() {
	@include font-book;
	@include font-size-body-lead;
}

@mixin font-style-body() {
	@include font-book;
	@include font-size-body;
}
@mixin font-style-body-small() {
	@include font-reg;
	@include font-size-body-small;
}
@mixin font-style-body-mini() {
	@include font-reg;
	@include font-size-body-mini;
}

@mixin font-style-footer-small() {
	@include font-light;
	@include font-size-footer-small;
}
@mixin font-style-footer() {
	@include font-reg;
	@include font-size-footer;
}
@mixin font-style-footer-big() {
	@include font-light;
	@include font-size-footer-big;
}


@mixin font-style-button() {
	@include font-reg;
	@include font-size-button;
}
@mixin font-style-button-service() {
	@include font-light;
	@include font-size-button-small;
	text-transform: uppercase;
}
@mixin font-style-button-switch() {
	@include font-bold;
	@include font-size-button-big;
}
@mixin font-style-button-switch-small() {
	@include font-light;
	@include font-size-button-small;
}
@mixin font-style-button-tag() {
	@include font-reg;
	@include font-size-button-small;
}

@mixin font-style-form() {
	@include font-light;
	@include font-size-form;
}
@mixin font-style-form-big() {
	@include font-light;
	@include font-size-form-big;
}



@mixin font-style-quote-text() {
	@include font-semi-light;
	@include font-size-qoute-text;
}
@mixin font-style-quote-author() {
	@include font-light;
	@include font-size-body-mini;

	STRONG {
		@include font-bold;
		@include font-size-body-small;
	}
}

@mixin font-style-teaser-tagline() {
	@include font-reg;
	@include font-size-body-small;
	//text-transform: uppercase;
}
@mixin font-style-teaser-title-big() {
	@include font-bold;
	@include font-size-h2;
}
@mixin font-style-teaser-title() {
	@include font-bold;
	@include font-size-h3;
}
@mixin font-style-teaser-subline() {
	@include font-light;
	@include font-size-body-small;
}

@mixin font-style-navigation() {
	@include font-reg;
	@include respond-to(display) {
		@include font-medium;
	}

	@include font-size-navigation;
}

@mixin font-style-navigation-small() {
	@include font-reg;
	@include respond-to(display_medium) {
		@include font-bold;
	}

	@include font-size-navigation-small;
	text-transform: uppercase;
}

@mixin font-style-image-caption() {
	@include font-light;
	@include font-size-image-caption;
}

@mixin font-style-numbers() {
	@include font-bold;
	@include font-size-numbers;
}

@mixin font-style-es-subline() {
	@include font-bold;
	@include font-size-es-subline;
}


/**
 * STYLES
 **/

HTML {
	/* Better Font Rendering =========== */
	-webkit-font-smoothing  : antialiased;
	-webkit-text-size-adjust: none;
	-moz-osx-font-smoothing : grayscale;

	font-size : 62.5%; // 62,5% => 10px (100% => 16px, 100% => 12pt), 1rem => 10px; 1rem => 7.5pt
	//font-size: 31.25%; // mobile
	//@include respond-to(phone) {
	//	font-size : 31.25%;
	//	//font-size : 46.875%;
	//}
	//@include respond-to(tablet_landscape) {
	//	font-size : 46.875%;
	//	//font-size : 54.687%;
	//}
	//@include respond-to(display) {
	//	font-size : 62.5%;
	//}
	//@include respond-to(display_large) {
	//	font-size : 62.5%; // 62,5% => 10px (100% => 16px, 100% => 12pt), 1rem => 10px; 1rem => 7.5pt
	//}

	@media print{
		font-size : 46.875% !important;
	}
}

BODY {
	@include font-style-body;

	.font--bold,
	STRONG {
		@include font-bold;
		//font-weight: $font-weight-bold;
	}

	.font--italic,
	em, i {
		font-style: italic;
	}

	.font {
		&--body-lead {
			@include font-style-body-lead;
		}

	}

	.editorstyle {
		$self: &;
		&--highlight {
			@include font-style-body-lead-book;
			color: var(--color-mint-darker);
			position: relative;
			display: block;
			padding: 3rem;

			&:before {
				content: '';

				width: 0.4rem;
				height: 100%;
				background: var(--color-mint-darker);

				position: absolute;
				top:0;
				left:0;
			}
		}

		&--subline {
			@include font-style-es-subline;
			color: var(--color-mint);
		}
	}

	H1 { @include font-style-headline-1 }
	H2 { @include font-style-headline-2 }
	H3 { @include font-style-headline-3 }
	H4 { @include font-style-headline-4 }
	H5 { @include font-style-headline-5 }
	H6 { @include font-style-headline-6 }

}

