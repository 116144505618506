.image {
	$self: &;

	//background: var(--color-lazy);

	PICTURE,
	IMG {
		transition: opacity $tt-lazy;
		display: block;
		vertical-align: bottom;
		opacity: 0;

		width: 100%;
		height: auto;
	}

	//PICTURE {
	//	IMG {
	//		width: 100%;
	//		height: auto;
	//	}
	//}

	&--cover {
		PICTURE, IMG {
			width: 100%;
			height: 100%;
			object-fit: cover ;
			object-position: center;
		}
	}

	&__wrapper {
	}

	&__image {

	}

	&__caption {
		@include font-style-image-caption;
	}

	&[style*="--aspect-ratio"] > :first-child {
		width: 100%;
	}
	&[style*="--aspect-ratio"] img {
		height: auto;
	}

	/* temp disabled
	.layout--livemode & {
		@supports (--custom:property) {
			&[style*="--aspect-ratio"] {
				position : relative;

				&:before {
					content        : '';
					display        : block;
					padding-bottom : calc(100% / (var(--aspect-ratio)));
				}

				& > :first-child {
					position : absolute;
					top      : 0;
					left     : 0;
					height   : 100%;
				}

			}

			&[style*="--sqip"] {
				&:before {
					background : var(--sqip);
					background-size: cover;
				}
			}
		}
	}*/


	.layout--livemode &,
	.layout--editmode & {
		IMG,
		PICTURE {
			opacity: 1 !important;
		}
	}

}