.buttons {
	$self: &;

	.layout--livemode & {
		display: flex;
	}

	&--left {
		justify-content: flex-start;

		.button {
			margin-right: 1rem;
		}
	}

	&--center {
		justify-content: center;

		.button {
			margin:0 0.5rem;
		}
	}

	&--right {
		justify-content: flex-end;

		.button {
			margin-left: 1rem;
		}

	}

	&--filters {
		.button {
			margin-right: 5rem;
			&:last-child {
				margin-right: 0;
			}
		}
	}

	&--tags {
		.button {
			margin-right: 2rem;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.button {
	$self: &;

	--button-color-mint: var(--color-mint);
	--button-color-black: var(--color-black);
	--button-color-grey: var(--color-grey-1);
	--button-color-grey-light: var(--color-grey-2);
	--button-color-white: var(--color-white);

	.theme-container--top & {
		--button-color-mint: var(--color-theme-button-mint-top);
		--button-color-black: var(--color-theme-button-black-top);
		--button-color-grey: var(--color-theme-button-grey-top);
		--button-color-white: var(--color-theme-button-white-top);

	}
	.theme-container--bottom & {
		--button-color-mint: var(--color-theme-button-mint-bottom);
		--button-color-black: var(--color-theme-button-black-bottom);
		--button-color-grey: var(--color-theme-button-grey-bottom);
		--button-color-white: var(--color-theme-button-white-bottom);
	}

	--button-background-color:      var(--button-color-white, var(--color-white));
	--button-border-color:          var(--button-color-white, var(--color-white));
	--button-text-color:            var(--button-color-grey,  var(--color-grey-1));

	@include font-style-button;

	transition: all $tt-cta ease;
	background: var(--button-background-color);
	border: 0.1rem solid var(--button-border-color);
	color: var(--button-text-color);
	appearance: none;
	cursor: pointer;


	margin:0;
	padding:0;

	height: 5.5rem;
	padding: 0 2.2rem;

	display: inline-flex;
	align-items: center;
	justify-content: center;

	&__icon {
		transition: all $tt-cta ease;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__label {
		#{$self}--hl-mobile & {
			@include respond-to(until_tablet) {
				display: none;
			}
		}
	}

	&__plus-minus {
		--bg: var(--button-border-color);
		--button-rotate-before: 0deg;
		--button-rotate-after: 90deg;

		position: relative;
		width: 100%;
		height: 100%;
		&:before,
		&:after {
			transform-origin: center;
			transform: translate(-50%) rotate(var(--button-rotate-before));
			content: ' ';
			position: absolute;
			top:50%;
			left:50%;

			width: 100%;
			height: 0.2rem;
			background: var(--bg);
		}

		&:after {
			transform: translate(-50%) rotate(var(--button-rotate-after));
		}

	}

	&__options {
		position: absolute;
		bottom:0;
		left:0;
		transform: translateY(100%);
		list-style: none;
		margin:0;
		padding: 1rem 0 0;
		//width: 100%;
		width: 30rem;
		z-index: 10;

		display: none;

		#{$self}--open & {
			display: block;
		}
	}

	&__option {
		@include font-style-body-small;
		padding: 0.5rem 2rem;
		text-align: left;

		&:first-child {
			padding-top: 2rem;
		}

		&:last-child {
			padding-bottom: 2rem;
		}

		&--active,
		&:hover {
			color: var(--color-mint);
		}
	}


	&--tag,
	&--primary {
		--button-border-color: var(--button-background-color);
		--button-text-color: var(--button-text-color);

		&:hover,
		&:focus,
		&:active {
			--button-background-color: var(--color-grey-4);
		}
	}

	&--secondary,
	&--outline {
		--button-background-color: transparent !important;
		--button-text-color: var(--button-border-color) !important;

		&:hover,
		&:focus,
		&:active {
			--button-border-color: var(--color-grey-1);
		}

	}

	/* specials */

	&--tag {

		flex-direction: row-reverse;
		height: 4rem;
		padding: 0 1.5rem 0 2.2rem;

		#{ $self }__icon {
			opacity: 0.5;
			transform: rotate(45deg);
			width: 1.8rem;
			height: 1.8rem;


			svg {
				width: 1.5rem;
				height: 1.5rem;

				fill: var(--button-text-color);
			}
		}

		#{ $self }__label {
			@include font-style-button-tag;
			margin-right: 1rem;
		}
	}

	&--back,
	&--service,
	&--switch {
		background: transparent;
		border: 0;
		padding: 0;
		height: auto;
	}

	&--back,
	&--service {
		@include font-style-button-service;





		#{ $self }__icon {
			background: var(--button-background-color) ;

			width: 4rem;
			height: 4rem;

			@include respond-to(tablet) {
				width: 5rem;
				height: 5rem;
			}
			@include respond-to(display) {
				width: 8rem;
				height: 8rem;
			}


			svg {
				width: 1.5rem;
				height: 1.5rem;

				@include respond-to(tablet) {
					width: 2rem;
					height: 2rem;
				}
				@include respond-to(display) {
					width: 3rem;
					height: 3rem;
				}

				fill: var(--button-border-color);
			}
		}

		#{ $self }__label {
			margin-left: 3.5rem;
		}
	}

	&--service {
		flex-direction: column;
		margin: 0 2rem 0.9rem ;

		#{ $self }__label {
			margin-left: 0;
			margin-top: 2.5rem;
		}
	}

	&--switch {
		@include font-style-button-switch;

		position: relative;
		justify-content: flex-start;
		color: var(--button-background-color);

		#{ $self }__icon {
			width: 4rem;
			height: 4rem;
			background: var(--button-background-color) ;

			padding: 1.25rem;

			svg {
				width: 1.25rem;
				height: 1.25rem;
				fill: var(--button-border-color);
			}
		}

		#{ $self }__label {
			margin-left: 2.5rem;
		}

		#{ $self }__plus-minus {
			--bg: var(--button-border-color);
		}

		#{ $self }__options {
			box-shadow: 0 10px 6px -6px $color-black-10;
		}

		#{ $self }__option {
			background: var(--button-color-white);
			background: #fcfcfc;
		}

		#{ $self }--small & {
			color: red;
		}
	}

	&--icon {
		background: transparent;
		width: 6rem;
		height: 6rem;
		padding: 0;
		border: 0;

		#{ $self }__icon {
			width: 100%;
			height: 100%;

			svg {
				fill: var(--button-border-color);
			}

		}
	}

	&--text {
		position: relative;
		display: inline-flex;
		align-items: center;
		background: transparent;
		border: none;
		margin: 0;
		padding: 0;
		width: auto;
		height: auto;
		font-size: inherit;

		#{ $self }__icon {
			width: 1.2rem;
			height: 1.2rem;
			margin-right: 0.5rem;

			svg {
				width: 100%;
				height: 100%;
				fill: var(--button-border-color);
			}
		}
	}



	/* colors */

	&--black {
		--button-background-color: var(--button-color-grey);
		--button-border-color: var(--button-color-grey);
		--button-text-color: var(--button-color-white);

		&#{ $self }--back,
		&#{ $self }--service,
		&#{ $self }--switch {
			--button-text-color: var(--button-color-mint);
			--button-border-color: var(--button-color-mint);
			--button-background-color: var(--button-color-grey);
		}

		&:hover,
		&:focus,
		&:active {
			--button-background-color: var(--button-color-black);

			&#{ $self }--secondary,
			&#{ $self }--outline {
				--button-border-color: var(--button-color-black);
			}

			&#{ $self }--back,
			&#{ $self }--service {
				--button-text-color: var(--button-color-grey);
				--button-border-color: var(--button-color-grey);
				--button-background-color: var(--button-color-mint);

			}
		}
	}

	&--grey {
		--button-background-color: var(--button-color-grey);
		--button-border-color: var(--button-color-grey);
		--button-text-color: var(--button-color-grey);

		&:hover,
		&:focus,
		&:active {
			--button-background-color: var(--button-color-mint);
			--button-border-color: var(--button-color-mint);
			--button-text-color: var(--button-color-mint);
		}
	}

	&--grey-light {
		--button-background-color: var(--button-color-grey-light);
		--button-border-color: var(--button-color-grey-light);
		--button-text-color: var(--button-color-grey-light);

		&:hover,
		&:focus,
		&:active {
			--button-background-color: var(--button-color-mint);
			--button-border-color: var(--button-color-mint);
			--button-text-color: var(--button-color-mint);
		}
	}

	&--mint {
		--button-background-color: var(--button-color-mint);
		--button-border-color: var(--button-color-mint);
		--button-text-color: var(--button-color-grey);

		&#{ $self }--back,
		&#{ $self }--service {
			--button-text-color: var(--button-color-mint);
			--button-border-color: var(--button-color-grey);
		}

		&#{ $self }--switch {
			--button-text-color: var(--button-color-white);
			--button-border-color: var(--button-color-grey);
		}

		&#{ $self }--text {
			--button-text-color: var(--button-background-color);
		}


		&:hover,
		&:focus,
		&:active {
			--button-background-color: var(--button-color-grey);
			--button-border-color: var(--button-color-grey);
			--button-text-color: var(--button-color-mint);

			&#{ $self }--secondary,
			&#{ $self }--outline {
				--button-border-color: var(--button-color-grey);
			}

			&#{ $self }--back,
			&#{ $self }--switch,
			&#{ $self }--service {
				--button-background-color: var(--button-color-grey);
				--button-text-color: var(--button-color-grey);
				--button-border-color: var(--button-color-mint);
			}

			&#{ $self }--text {
				--button-text-color: var(--button-color-grey);
				--button-border-color: var(--button-color-grey);
			}
		}
	}

	&--small {
		&#{ $self }--primary {
			@include font-style-button-switch-small;
			height: 4.5rem;
		}

		&#{ $self }--switch {
			@include font-style-button-switch-small;
		}

		&#{ $self }--service {
			#{ $self }__icon {
				width: 4rem;
				height: 4rem;
				padding: 1.25rem;

				svg {
					width: 1.25rem;
					height: 1.25rem;
				}
			}

			#{ $self }__label {
				margin-top: 1rem;
			}
		}

	}




	&[disabled],
	&--disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&--right {
		margin-left: auto;
		margin-right: 0;

		&#{ $self }--switch {
			#{ $self }__label {
				margin-right: 2.5rem;
			}
		}
	}



}