.timeline {
	$self: &;

	position: relative;

	&--content-left {

	}

	&--content-right {
		#{$self}__content {

			text-align: left;
		}
	}

	&--last {
		&#{$self}__container,
		#{$self}__container {
			margin-bottom: 0;
			padding-bottom: 12rem;
		}
	}


	&__container {
		position: relative;
		z-index: 10;
		margin-bottom: 6rem;
		//align-items: center;
	}

	&__wrapper {
		position: relative;
	}

	&__line-wrapper {
		display: none;
		@include respond-to(display) {
			display: flex;
			align-items: flex-start;
			justify-content: center;
		}

		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom: 0;

		pointer-events: none;


	}

	&__line {

		.theme-container--top & {
			--line-color: var(--color-theme-seperator-top, var(--color-mint));
		}
		.theme-container--bottom & {
			--line-color: var(--color-theme-seperator-bottom, var(--color-mint));
		}


		position: relative;
		background: var(--line-color);
		width: 0.2rem;
		height: 100%;

		z-index: 0;

		&:after {
			transform: translateX(-50%);
			content: '';
			position: absolute;
			bottom:0;
			left: 0;
			width: 1rem;
			height: 1rem;

			background: var(--line-color);
		}
	}

	&__content {
		@include respond-to(display) {
			text-align: right;
		}
	}

	&__cube-wrapper {

	}

	&__cube {


		.theme-container--top & {
			--bg-color: var(--color-theme-container-top, var(--color-page-background));
			--border-color: var(--color-theme-seperator-top, var(--color-mint));

		}
		.theme-container--bottom & {
			--bg-color: var(--color-theme-container-bottom, var(--color-page-background));
			--border-color: var(--color-theme-seperator-bottom, var(--color-mint));
		}

		background: var(--bg-color);
		color: var(--color-mint);

		width: 100%;

		@include respond-to(tablet) {
			border: 0.2rem solid var(--border-color);
			width: 15rem;
			height: 15rem;
		}


		display: flex;
		align-items: center;
		justify-content: center;

		SPAN {
			background: var(--color-black);

			display: flex;
			align-items: center;
			justify-content: center;

			width: 5rem;
			height: 5rem;
		}
	}
}