.layout--blog-detail {
	.footer {
		padding-top: 0;
	}
}

.blog-detail {
	//padding-top: var(--layout-header-height);

	&__intro {
		& + SECTION.grid {
			margin-top: 2rem;
		}
	}

	&__metas {
		@include respond-to(until_tablet) {
			display: grid;
			grid-template-columns: 50% 50%;
			grid-template-rows: auto auto;
			grid-template-areas:
				'top top'
				'center center'
				'bottom bottom'
			;
		}
	}

	&__meta {
		--color-highlight: var(--color-mint);

		@include font-style-body-small;

		&--category {
			grid-area: top;
			color: var(--color-highlight);
		}

		&--date {
			grid-area: center;
		}

		&--ttr {
			@include respond-to(until_tablet) {
				display: none;
			}

			margin: 2rem 0;

			display: flex;
			align-items: center;
			svg {
				width: 2.5rem;
				height: 2.5rem;
				margin-right: 0.7rem;
				margin-top: 0.3rem;
				fill: var(--ttr-color);
			}
		}

		&--author {
			grid-area: bottom;
			SPAN {
				display: block;
				&:first-child {
					text-decoration: underline;
				}
			}
		}

		&--social {
			@include respond-to(until_tablet) {
				display: none;
			}
			margin-top: 2rem;
			SPAN {
				//color: var(--color-highlight);
			}

			A {
				--color: var(--color-highlight);
				--color-hover: var(--color-black);
			}
		}
	}
}

.blog-controls {
	$self: &;

	padding-top: 2rem;

	&:last-child {
		margin-bottom: var(--layout-section-spacing);
	}

	.layout--blog-listing & {
		padding-top:0;
	}

	&__previous,
	&__next {
		@include respond-to(until_tablet) {
			display: none;
		}
	}


	&__top {
		margin-bottom: 3rem;
		padding-top: 3rem;
		border-top: 0.2rem solid var(--color-grey-4);

		.layout--blog-listing & {
			border-top: 0;
		}
	}

	&__bottom {
		@include font-style-body-small;
	}

	&__bottom-left {
		@include respond-to(until_tablet) {
			display: none;
		}
		display: flex;
		justify-content: space-between;
	}

	&__bottom-right {
		margin-top: 3rem;
		@include respond-to(tablet) {
			margin-top: unset;
		}
	}

	&__social {
		SPAN {
			//color: var(--color-mint);
		}

		A {
			--color: var(--color-mint);
			--color-hover: var(--color-black);
		}
	}

	&__talk {
		.headline {
			color: var(--color-mint);
			margin-bottom: 1rem;
			margin-top:0;
		}

		P {
			@include font-style-body-small;
			@include respond-to(tablet) {
				width: 55%;
			}
		}
	}
}

.blog-related {
	.headline {
		color: var(--color-mint);
	}
}

.blog-listing {

	&__filters-tags {
		margin-top: 2rem;
	}

	&__filters {
		z-index: 65;
	}

	&__container {
		z-index: 50;
	}


	.grid--bgc + .grid--bgc {
		padding-top: 3rem !important;
		grid-row-gap: 3.2rem;
		@include respond-to(tablet) {
			grid-row-gap: 6.4rem;
		}

	}
}

.blog-load-more {
	.grid__column {
		padding-top: var(--layout-section-spacing);
		padding-bottom: calc(var(--layout-section-spacing) / 2);
	}

}