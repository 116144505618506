.hamburger {
	appearance: none;
	border:0;
	margin:0;
	padding:0;
	background: transparent;
	cursor: pointer;

	width: 3rem;
	height: 3rem;

	svg {
		fill: var(--header-color);
	}


	/** svg id **/
	#cross {
		visibility: hidden;
	}

}