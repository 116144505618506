.tags {
	$self: &;

	--color: var(--color-mint-darker);
	--color-seperator: var(--color-mint-darker);

	margin: 0;
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;

	&__item {
		display: flex;
		align-items: center;

		&:after {
			display: block;
			padding:0 0.5rem;
			content: '/';
			color: var(--color-seperator);
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&__link {
		color: var(--color);
		border-bottom: 0.2rem solid transparent;
		&:hover {
			color: var(--color-black);
			border-bottom: 0.2rem solid var(--color-black);
		}
	}
}