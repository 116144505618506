.scroll-indicator {
	$self: &;

	transition: all $tt-std ease;
	position: fixed;
	top:0;
	left: 0;
	z-index: $z-scroll-indicator;

	height: 100%;
	width: 100%;


	display: none;
	@include respond-to(display) {
		display: flex;
	}

	align-items: center;

	opacity: 1.5;
	pointer-events: none;
	user-select: none;

	&:hover {
		opacity: 1;
	}

	&--hidden {
		display: none;
	}

	&--label-hidden {
		#{$self}__label {
			opacity: 0;
		}

		#{$self}__bar {
			//background: var(--color-scroll-indicator);
		}
	}

	&__container {
		width: 100%;
		min-width: var(--layout-min-width);
		max-width: var(--layout-max-width);
		margin:0 auto;
		padding-left: 5rem;

	}

	&__wrapper {
		position: relative;
		width: 3rem;
		height: 20vh;

		display: flex;
		justify-content: center;
		pointer-events: all;

		//background: black;
	}

	&__bar,
	&__label {
		pointer-events: none;
	}

	&__bar {
		--line-stop: 25%;
		--line-stop-end: calc(100% - var(--line-stop));


		width: 0.1rem;
		height: 100%;
		//background: var(--color-scroll-indicator);
		transition: all $tt-std ease;
		//transition-delay: $tt-std;

		background: linear-gradient(to bottom,
			var(--color-scroll-indicator) 0%,
			var(--color-scroll-indicator) var(--line-stop),
			rgba(255,0,0,0) var(--line-stop),

			rgba(255,0,0,0) var(--line-stop-end),
			var(--color-scroll-indicator) var(--line-stop-end),
			var(--color-scroll-indicator) 100%);
	}

	&__label {
		@include font-style-body-mini;
		text-transform: uppercase;

		transition: all $tt-cta ease;
		position: absolute;
		top:0;
		left:0;

		height: 100%;
		width: 100%;

		opacity: 1;
		text-align: center;
		transform: rotate(180deg);
		writing-mode: vertical-rl;

		SPAN {
			display: inline-flex;
			align-items: center;
			justify-content: center;

			//background: var(--color-page-background);
			width: 100%;
			padding: 1rem 0 1rem 0.2rem;

			color: var(--color-scroll-indicator);
		}
	}

	&__dot {
		transform: translateX(-50%) translateY(-50%);
		transform-origin: center;
		position: absolute;
		top:0;
		left:50%;

		pointer-events: all;

		background: var(--color-scroll-indicator);
		width: 1.5rem;
		height: 1.5rem;

		//border: 0.2rem solid var(--color-page-background);
		border-radius: 1.5rem;
		cursor: pointer;
	}


}