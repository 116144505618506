.video {
	$self: &;

	position: relative;

	&--youtube {

		.layout--livemode & {

			&:before {
				content: "";
				display: block;
				padding-top: 56%; /* 16:9 Format */
			}

			IFRAME {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}
	}


	&__wrapper {
	}

	&__controls {
		transition: all $tt-std ease;
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		height: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		background: var(--color-black-30);

		#{$self}--playing & {
			opacity: 0;
			&:hover {
				opacity: 0.8;
			}
		}
	}

	&__control {
		appearance: none;
		background: none;
		border:0;
		cursor: pointer;


		&--play-pause {
			width: 8rem;
			height: 8rem;
			svg {
				fill: var(--color-mint);

				#{$self}--playing & {
					.pause {
						display: block;
					}

					.play {
						display: none;
					}
				}
			}
		}
	}

	VIDEO {
		width: 100%;
		height: auto;
	}
}