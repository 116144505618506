.quote {
	$self: &;

	.theme-container--top & {
		--bracket-color: var(--color-theme-seperator-top, var(--color-mint));
	}
	.theme-container--bottom & {
		--bracket-color: var(--color-theme-seperator-bottom, var(--color-mint));
	}

	margin: 20rem 0 10rem !important;
	@include respond-to(tablet_landscape) {
		margin: 20rem 0 !important;
	}

	.layout--editmode & {
		margin-top: 0;
	}

	.section-intro {
		margin-bottom: 20rem;
	}

	&__container {
		background: var(--color-grey-1);
	}

	&__bracket {
		display: block;
		transform: translateY(-50%);

		svg {
			fill:var(--bracket-color);
		}

		&--right {
			transform: translateY(50%);
		}
	}

	&__content {
		text-align: center;
		padding-bottom: 10rem;

		/* values / vars definied in grid.scss */
		display: grid;
		@include generate-inner-grid-template-columns-only-column(9);

	}

	&__image {
		transform: translateY(-30%);
		grid-column: column-start 3 / column-end 8;
		@include respond-to(tablet) {
			transform: translateY(-50%);
			grid-column: column-start 4 / column-end 7;
		}
		@include respond-to(display) {
			grid-column: column-start 5 / column-end 6;
		}

		//background: var(--bracket-color);

		//demo
		//min-height: 19rem;

		.image,
		.image__wrapper {
			width: 100%;
			height: 100%;

			IMG, PICTURE {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

	}

	&__text {
		@include font-style-quote-text;
		color: var(--color-grey-4);

		grid-column: column-start 1 / column-end 10;

		P {
			margin-top: 0;
		}
	}

	&__author {
		@include font-style-quote-author;
		color: var(--color-mint);

		STRONG {

		}

	}
}

.quote-inline {
	$self: &;

	background: var(--color-mint);
	color: var(--color-white);
	clip-path: polygon(0% 0%, 0% 100%, 1rem 100%, 4.5rem calc(100% - 4rem), 100% calc(100% - 4rem), 100% 0%);

	&__content {
		padding: 5rem 6rem 9rem;
	}

	&__text {
		@include font-style-headline-2;
	}

	&__author {
		@include font-style-body;
		margin-top: 2rem;
	}

}