.overlay {
	$self: &;

	position: fixed;
	top:0;
	left:0;
	z-index: $z-overlay !important;

	width: 100%;
	height: 100%;

	display: none;
	//grid-template-columns: 1fr;
	//grid-template-rows: var(--layout-header-height, 1fr);
	//grid-template-areas:
	//	'top'
	//	'content'
	//;



	background: var(--color-black);
	opacity: 0;


	&--ready {
		display: block;
	}

	&__close {
		//transform: translateY(-100%);
		display: block;
		position: absolute;
		top:0;

		right: 0;
		@include respond-to(tablet_landscape) {
			right: var(--layout-header-height);
		}
		width: var(--layout-header-height);
		height: var(--layout-header-height);

		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		svg {
			width: 3.5rem;
			height: 3.5rem;
			fill: var(--color-white);
		}

	}

	&__content {
		width: 100%;
		height: 100%;
		padding: var(--layout-header-height) 3rem;

		.pimcore_tag_video {
			width: 100%;
			height: 100%;
		}
	}

	&__media {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}