.numbers {
	$self: &;

	margin-top: 6rem;
	margin-bottom: 6rem;

	--color: var(--color-mint);

	.grid__container {
		background: linear-gradient(311.01deg, #000000 0%, #221321 100%);
		padding-top: 6rem;
		padding-bottom: 10rem;
	}

	&__container {
		position: relative;

		display: grid;
		grid-template-columns: 35% 1fr 55%;
		grid-template-areas:
			'left-side      center-space    .'
			'.              center-space    right-side'
		;
	}

	&__icon {
		grid-area: left-side;
		justify-self: flex-end;

		width: 12rem;
		height: 12rem;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--color);
		}
	}

	&__line {
		grid-area: center-space;
		justify-self: center;
		//position: absolute;
		//top:0;
		//left: 50%;
		//
		transform: rotate(45deg);
		width: 0.4rem;
		//height: 100%;
		height: 24rem;
		background: var(--color);
	}

	&__content {
		grid-area: right-side;
		overflow: hidden;
	}

	&__value {
		white-space: nowrap;
		display: flex;
		align-items: center;
		flex-wrap: nowrap;
	}

	&__value-value,
	&__value-suffix {
		@include font-style-numbers;
	}

	&__value-value {

	}

	&__value-suffix {

	}

	&__value-value,
	&__value-suffix,
	&__label {
		color: var(--color-white);
	}

	&__label {
		@include font-style-body-lead;
	}
}