.lazy {
	$self: &;

	IMG, PICTURE {
		transition: opacity $tt-lazy ease-out;
		opacity: 0;
	}

	&--loading {
		IMG, PICTURE {
			opacity: 0;
		}
	}

	&--loaded {
		IMG, PICTURE {
			opacity: 1;
		}
	}

	&--error {

	}
}