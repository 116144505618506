/* http://pristine.js.org form validation */

.form {
	@include font-style-form;

	$self: &;
	$formgroup: '.form__group';

	$inputHeight: 5rem;


	--form-group-spacebetween: 2.5rem;
	--form-input-background: var(--color-white);


	&--contact {
		margin-top: 6rem;
	}

	&__group {
		&--nvc {
			visibility: hidden;
			display: none;
		}
	}



	&__splitter {
		@include respond-to(tablet) {
			display: flex;
			justify-content: space-between;

			#{$formgroup} {
				width: 50%;

				&:first-child {
					margin-right: var(--form-group-spacebetween);
				}
			}

			&--left {
				#{$formgroup}:first-child {
					width: 35%;
				}

				#{$formgroup}:last-child {
					width: calc(65% - var(--form-group-spacebetween));
				}
			}

			&--right {
				#{$formgroup}:first-child {
					width: 65%;
				}

				#{$formgroup}:last-child {
					width: calc(35% - var(--form-group-spacebetween));
				}
			}
		}

	}

	&__group {
		position: relative;
		margin-bottom: var(--form-group-spacebetween);

		&--has-success {

		}

		&--has-error {

		}
	}

	&__icon {
		position: absolute;
		top:#{$inputHeight / 2};
		right: 2rem + 0.2rem; //input padding  + border
		transform: translateY(-50%);


		width: 2rem;
		height: 2rem;

		display: none;

		#{$formgroup}--has-error &,
		#{$formgroup}--has-success & {
			display: block;
		}

		#{$formgroup}--has-error & {
			background: var(--color-validation-error);
		}

		#{$formgroup}--has-success & {
			background: var(--color-validation-success);
		}

	}

	&__section-label {
		@include font-style-body-small;
		@include font-reg;
		display: block;
		margin: 1.2rem 0 2rem;

		margin-top: 6rem;
		&:first-child {
			margin-top: 1.2rem;
		}
	}

	&__submit-container {
		margin-top: 6rem;
	}

	&__tooltip {

	}

	&__message {
		color: var(--color-white);
		background: var(--color-validation-success);
		margin-bottom: 1.8rem;
		padding: 0.5rem 0;
		text-align: center;

		&--error {
			background: var(--color-validation-error);
		}
	}


	&__input-cta__input,
	&__input {
		&::placeholder {
			opacity: 0.5;
		}

		//&::-webkit-input-placeholder {
		//	line-height:normal!important;
		//}

		width: 100%;
		height: 5rem;
		padding: 0 2rem;
		border: 0.2rem solid var(--color-grey-4);
		border-radius: 0.2rem;
		background: var(--form-input-background);
		font-weight: 300;

		@at-root #{$formgroup}--has-error & {
			border-color: var(--color-validation-error);
		}

		&:focus + .tooltip {
			opacity: 0;
		}

	}

	&__input-nvc {
		visibility: hidden;
		display: none;
	}

	&__input-cta {
		--input-cta-height: 5rem;
		@include respond-to(tablet) {
			--input-cta-height: 8rem;
		}

		display: flex;
		align-items: center;
		height: var(--input-cta-height);


		&__input {
			@include font-style-form-big;
			display: flex;
			align-items: center;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			&::placeholder {
				//font-size: 0.5em;
				//include font-style-form;
				//line-height: var(--input-cta-height);
				//height: var(--input-cta-height);

			}

			height: inherit;
			width: calc(100% - var(--input-cta-height));
			border-right: 0;
		}

		&__button {
			@include font-style-form;
			appearance: none;

			//display: flex;
			display: block;
			align-items: center;
			justify-content: center;


			border:0;
			width: 8rem;
			height: var(--input-cta-height);
			padding: 0.5rem;
			background: var(--color-mint);


			cursor: pointer;

			@include respond-to(tablet) {
				padding: 2rem;
			}


		}

		&__icon {
			opacity: 0.5;
			width: 100%;
			height: 100%;

			svg {
				height: calc(var(--input-cta-height) - 2rem);
				height: 100%;
			}

			//background: var(--color-grey-1);
		}

		&__autofill {
			transform: translateY(1rem);
			position: absolute;
			left:0;
			top:100%;
			z-index: 110;

			margin:0;
			padding:1rem 0;
			list-style: none;

			//width: calc(100% - 8rem);
			width: 100%;
			max-height: 20rem;
			background:var(--color-white);

			LI {
				display: flex;
				align-items: center;

				padding: 0 2rem;
				height: 3.6rem;
				cursor:pointer;

				&:hover {
					background: var(--color-mint);
				}
			}

		}
	}

	&__radio,
	&__checkbox {
		display: none;



		& + LABEL {

			& + .tooltip {
				display: none;
			}

			cursor: pointer;
			//display: flex;
			//align-items: center;

			&:before {
				transition: all $tt-cta ease;
				width: 3.5rem;
				height: 3.5rem;
				background: var(--form-input-background);

				border: 0.2rem solid var(--color-grey-4);
				border-radius: 0.2rem;

				display: block;
				content: "";
				float: left;

				margin-right: 1.3rem;
				transform: translateY(-0.5rem);

				@at-root #{$formgroup}--has-error & {
					border-color: var(--color-validation-error);
					background: var(--form-input-background);
				}

			}

		}

		&:checked + LABEL {
			&:before {
				background: var(--color-mint);
				border-color: var(--color-mint);
			}
		}

		&[disabled],
		&[disabled] + LABEL {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&__radio {
		& + LABEL {
			&:before {
				border-radius: 3.5rem;
			}
		}
	}



	TEXTAREA {
		padding-top: 1.2rem;
		padding-bottom: 1.2rem;
	}



	&--newsletter {
		#{$self}__input-cta {
			--input-cta-height: 5.5rem;

			&__input {
				@include font-style-form;
				border-radius: 0;
				background: transparent;
				border-color: var(--color-white);
				color: var(--color-white);

				&::placeholder {

					//line-height: var(--input-cta-height);
					//height: var(--input-cta-height);

				}

			}

			&__button {
				background: var(--color-white);
				padding: 0 2rem;
				width: auto;
			}
		}

	}


	.dropdown-instance {
		.selectr-selected {

			display: flex;
			align-items: center;

			height: 5rem;
			padding: 0 2rem;
			border: 0.2rem solid var(--color-grey-4);
			border-radius: 0.2rem;
			background: var(--form-input-background);


			&:before {
				border-color: var(--color-mint) transparent transparent;
			}
		}

		.selectr-options {
			max-height: 25rem;
		}

		.selectr-option {
			@include font-style-form;

			padding: 0.5rem 2rem;
			height: 3.6rem;

			&.active {
				color: inherit;
				background: var(--color-mint);
			}

			&.selected {
				background: var(--color-mint-green);
			}
		}
	}
}




.form__upload {
	--dnd-border-color: var(--color-grey-4);

	&-zone {

		display    : block;
		width      : 100%;
		margin     : 2rem auto 4rem auto;
		padding    : 2.5rem;
		background : var(--color-theme-container-bottom, var(--color-white));
		border     : 2px dashed var(--dnd-border-color);
		text-align : center;
		cursor     : pointer;
		transition : box-shadow $tt-std, border-color $tt-std, color $tt-std;

		.text {
			span {
				display : block;
			}
		}

		.icon {
			width: 4rem;
			height: 4rem;
			fill: var(--color-mint);
			margin: 0 auto;
		}

		&.dragged {
			border-color : $color-black;
			color        : $color-black;
		}
	}

	.upload-file-row {
		border: 0.2rem solid var(--dnd-border-color);
		padding: 1.6rem;
		display: flex;
		align-items: center;
		margin-bottom:2rem;

		.left,
		.right {
			width: 10%;
		}

		.center {
			width: 80%;
			text-align: left;
		}

		.right {
			text-align: right;
		}

		.left svg {
			width: 3.2rem;
			height: 4.2rem;
			fill: var(--color-mint);
		}

		.center {
			SPAN {
				display: block;
				width:100%;
			}

			.filename {
				@include font-size-body-small;
				line-height: 1;
			}

			.filesize {
				@include font-size-body-mini;
			}
		}

		button {
			background: transparent;
			border:0;
			cursor: pointer;

			svg {
				width: 2rem;
				height: 2rem;
				fill: var(--color-mint);
			}
		}

		.dz-remove {
			display: none;
		}
	}
}