.triangle {
	$self: &;

	position: absolute;
	top:0;
	left:0;
	z-index: $z-triangle;
	width: 100%;
	pointer-events: none;
	user-select: none;

	display: none;

	@include respond-to(tablet_landscape) {
		.layout--has-triangle & {
			display: block;
		}
	}

	&__container {
		width: 100%;
		min-width: var(--layout-min-width);
		max-width: var(--layout-max-width);
		height: 100rem;
		margin: 0 auto;
	}

	&__item {
		width: 0;
		height: 0;
		border-top: var(--layout-triangle-dimension) solid var(--color-triangle);
		border-right: var(--layout-triangle-dimension) solid transparent;
	}


	.grid {
		pointer-events: none;
		height: 1px;
		opacity: 0;
		visibility: hidden;
	}
}