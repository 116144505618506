.section {
	$self: &;

	&__column {
		background: rgba($color-mint-dark, 0.5);
		//height: 20rem;

		margin-bottom: 2.5rem;
		@include respond-to(tablet) {
			margin-bottom: 5rem;
		}


	}


	margin-top: 5rem;
	@include respond-to(tablet) {
		margin-top: 10rem;
	}

}

SECTION {
	&:not(.seperator) + SECTION {
		margin-top: var(--layout-section-spacing);
	}

	&.page-controls + SECTION {
		margin-top: 0;
	}
}

.section-intro {
	margin-bottom: 5rem;
	text-align: center;
}