.layout--career-detail {
	.theme-container--top {
		margin-top: var(--layout-header-height);
		padding-top: calc(var(--layout-header-height) / 2);
	}
}

.layout--career-detail-clean {
	.theme-container--top {
		margin-top: 5rem;
		padding-top: 0;
	}
}

.job {
	$self: &;

	--color-highlight: var(--color-mint);

	&__title {
		@include font-style-intro;
		margin-top: 0;
	}

	&__id {
		margin-bottom: 3.8rem;
		@include respond-to(display) {
			margin-bottom: 3.8rem;
		}
	}

	&__informations,
	&__contact {
		@include font-style-body-small;
		position: relative;
	}

	&__controls {
		text-align: left;
		margin-top: 3rem;
		margin-bottom: 4.8rem;

		@include respond-to(tablet) {
			margin-top: 1rem;
		}

		@include respond-to(display) {
			text-align: right;
			//margin-bottom: 13rem;
			margin-bottom: 22rem;
			//margin-bottom: 26.5rem;

		}
	}

	&__social {
		margin-top: 1.8rem;
		@include respond-to(display) {
			margin-bottom: 3.8rem;
		}

		SPAN {
			//color: var(--color-highlight);
		}

		A {
			--color: var(--color-highlight);
			--color-hover: var(--color-black);
		}
	}

	&__apply-online-btn {


		position: absolute;
		top:0;
		right: 0;

		@include respond-to(tablet) {
			margin-top: 2.8rem;
			margin-bottom: 2.8rem;
			position: relative;
			top:0;
			right: unset;
		}
	}

	&__property {
		//include font-style-body-lead;
		display: flex;
		align-items: center;
		margin-bottom: 1rem;

		.inline-svg {
			width: 3rem;
			height: 3rem;
			margin-right: 2rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		svg {
			width: 2rem;
			fill: var(--color-highlight);
			@include respond-to(tablet) {
				width: 3rem;
				height: auto;

			}

		}

		&--contactperson {
			//include font-style-headline-3;
			margin-top: 2.5rem;
			margin-bottom: auto;

		}
	}

	&__property-content {

	}

	&__property-title,
	&__property-value {
		display: block;
	}

	&__property-value {
		color: var(--color-highlight);
	}

	&__contact-property {
		@include font-size-body-small;
		display: inline-flex;
		align-items: center;

		//margin-top: 0.5rem;
		//margin-bottom: 2rem;

		&--phone {
			margin-bottom: 0;
		}
	}

	&__description {
		margin-bottom: 6rem;
	}

	&__details {
		margin-bottom: 6rem;
		overflow-x: hidden;
	}

	&__details-tools {
		margin: 3rem auto 3rem;
	}

	&__detail {

		margin-bottom: 3rem;
		@include respond-to(tablet) {
			margin-bottom: 6rem;
		}

		@include respond-to(display) {
			padding-right: var(--layout-grid-gap);
		}

		&--benefits {
			margin-bottom: 6rem;
		}

	}

	&__benefits {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__benefit {
		@include font-style-body;

		display: grid;
		grid-template-columns: 8rem 1fr;
		grid-column-gap: var(--layout-grid-gap);
		align-items: center;
		margin-bottom: 2rem;
		@include respond-to(display) {
			margin-bottom: 4rem;
		}

	}

	&__benefit-icon,
	&__benefit-label {

	}



	&__apply {

	}

	&__apply-meta {

	}
	&__apply-title {
		margin: 1rem 0;
	}
	&__apply-location {

	}

	&__apply-form {
		margin-top: 7rem;

		TEXTAREA {
			min-height: 20rem;
		}

		.buttons {
			margin-top: 6rem;
		}
	}

	&__similarities {
		padding-top: 10rem;
	}
}