.navigation {
	$self: &;

	--border-color: var(--color-theme-navi-border, var(--color-mint));

	A {
		color: var(--header-color, var(--color-grey-1));

	}

	UL {
		list-style: none;
	}

	&--primary {

	}

	&--secondary {

		@include respond-to(until_display_large) {
			display: none !important;
		}


		transition: all 0.3s ease;
		transform: translateX(-50%) translateY(4rem);
		position: absolute;
		left:50%;
		padding: 1rem 2rem 2rem;
		display: block;

		//display: none !important;
		background: var(--color-black-50);
		border-top: 0.4rem solid var(--border-color);

		visibility: hidden;
		opacity: 0;
		pointer-events: none;


		.header--collapsed & {
			display: none;
		}
	}

	&--meta {

	}

	&__container {
		#{$self}--primary & {
			margin:0;
			padding:0;

			@include respond-to($layout-navigation-hamburger-breakpoint) {
				display: flex;
				align-items: center;
			}
		}

		#{$self}--meta & {
			margin:0;
			padding:0;
			margin: 1.8rem 0;

			.menu & {
				display: flex;
				align-items: center;
			}
		}
	}

	&__item {
		#{$self}--primary & {
			position: relative;
			text-align: center;
			opacity: 1;



			@include respond-to($layout-navigation-hamburger-breakpoint) {
				opacity: 1;
				text-align: left;
				padding: 1.5rem 1.5rem;

			}
			@include respond-to(display_medium) {
				padding: 1.5rem 2.5rem 1rem;
			}

			& > UL:hover,
			&:hover > UL,
			&:focus-within > UL {
				visibility: visible;
				opacity: 1;
				pointer-events: all;
				transform: translateX(-50%) translateY(-0.2rem);
			}
		}

		#{$self}--secondary & {
			white-space: nowrap;
			padding:0;
		}

		#{$self}--meta & {
			.menu & {
				padding: 0 1.5rem;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	&__link {


		#{$self}--primary & {
			@include font-style-navigation;

			display: block;


			@include respond-to($layout-navigation-hamburger-breakpoint) {
				border-bottom: 0.4rem solid transparent;
				padding: 1rem 0;
				&:hover {
					color: var(--border-color);
				}

			}

			&.active {
				@include respond-to($layout-navigation-hamburger-breakpoint-until) {
					color: var(--color-white);
				}

				@include respond-to($layout-navigation-hamburger-breakpoint) {
					border-bottom: 0.4rem solid var(--border-color);
					&:hover {
						border-color: transparent;
					}
				}
			}
		}

		#{$self}--secondary & {
			padding:0.5rem 0;
			color: var(--color-white);

			&.active,
			&:hover {
				color: var(--border-color);
			}

			&.active {
				border: 0;
			}
		}

		#{$self}--meta & {
			//include font-style-footer-small;

			@include respond-to($layout-navigation-hamburger-breakpoint) {
				color: var(--color-white);
				&:hover {
					color: var(--color-mint);
				}
			}

			.footer & {
				color: var(--color-white);
				&:hover {
					color: var(--color-mint);
				}
			}

		}
	}



}