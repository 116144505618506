.teaser {
	$self: &;

	--background-color: var(--color-grey-5);
	--background-color-copy: var(--color-mint);

	--tagline-color: var(--color-mint);
	--title-color: var(--color-grey-1);
	--subline-color: var(--color-grey-1);
	--date-color: var(--color-black);
	--ttr-color: var(--color-black-30);


	--height: 0rem;

	display: block;
	width: 100%;

	@include respond-to(until_tablet_landscape) {
		height: 100%;
	}

	.theme-container--top & {
		--background-color-copy: var(--color-theme-teaser-hover-top, var(--color-mint));
	}
	.theme-container--bottom & {
		--background-color-copy: var(--color-theme-teaser-hover-bottom, var(--color-mint));

		&#{$self}--document {
			--background-color-copy: var(--color-grey-5);
		}
	}

	&--document {
		//--background-color-copy: var(--color-grey-1) !important;
	}



	&--document-2,
	&--document,
	&--job,
	&--blog {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;

		height: 100%;

		--p: -100%;

		//--clip-path: polygon(0px 0px, 0% 0px, 0% 100%, 0% 100%);
		//--clip-path: polygon(0 0, calc(var(--p) + 25rem) 0, var(--p) 100%, 0% 100%);
		--clip-path: polygon(0 0, calc(var(--p) + var(--height)) 0, var(--p) 100%, 0% 100%);

		&:focus,
		&:hover {
			--p: 80%;

			//--p: 100%;

			//--clip-path: polygon(0px 0px, 30% 0px, 30% 100%, 0% 100%);
			//--clip-path: polygon(0 0, calc(var(--p) + 25rem) 0, var(--p) 100%, 0% 100%);
			--clip-path: polygon(0 0, calc(var(--p) + var(--height)) 0, var(--p) 100%, 0% 100%);
		}

	}

	&--blog,
	&--blog-big {
		transition: all $tt-std ease;

		&:hover {
			--tagline-color: var(--color-white);
			--title-color: var(--color-white);
			--subline-color: var(--color-white);
			--date-color: var(--color-white);
			--ttr-color: var(--color-white);
			--background-color: var(--color-mint) ;

			IMG {
				transform: scale(1.1);
			}
		}
	}

	&--blog {
		grid-template-rows: min-content 1fr;
		--background-color: var(--color-white);
	}

	&--blog-big {
		grid-template-rows: auto 1fr;

		@include respond-to(tablet_landscape) {
			grid-template-rows: 1fr;
			grid-template-columns: 48.75% 1fr;
		}

		@include respond-to(display_medium) {
			grid-template-columns: 66% 1fr;

		}
	}

	&--job {
		--background-color: var(--color-white);
	}

	&--manual {

	}

	&--no-link {
		.layout--livemode & {
			pointer-events: none;
			user-select: none;
			cursor: default;
		}
	}



	&__meta {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__date {
		color: var(--date-color);
	}

	&__ttr {
		color: var(--ttr-color);
		display: flex;
		align-items: center;
		svg {
			width: 2.5rem;
			height: 2.5rem;
			margin-left: 0.7rem;
			margin-top: 0.3rem;
			fill: var(--ttr-color);
		}

	}

	&__content-copy,
	&__content {
		position: relative;
		z-index: 5;

		#{ $self }--document-2 &,
		#{ $self }--document &,
		#{ $self }--job &,
		#{ $self }--blog-big &,
		#{ $self }--blog &{
			grid-column: 1;
			grid-row: 1;

			height: auto;
			@include respond-to(tablet_landscape) {
				min-height: 25rem;
			}

			padding: 2rem 2.5rem;
			overflow: hidden;

			background: var(--background-color);

			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: min-content 1fr min-content;
			grid-template-areas:
				'top'
				'center'
				'bottom'
			;
		}

		#{ $self }--blog-big &,
		#{ $self }--blog & {

			grid-row: 2;

		}

		#{ $self }--blog-big & {

			grid-row: 2;
			grid-column: 1;

			@include respond-to(tablet_landscape) {
				grid-row: 1;
				grid-column: 2;

			}


			grid-template-rows: min-content min-content 1fr min-content;
			grid-template-areas:
				'top'
				'center'
				'text'
				'bottom'
			;

			padding-top: 5rem;
			height: 100%;
		}

		#{ $self }--document & {
			text-align: center;
			grid-template-rows: min-content 1fr 1fr;
		}

		#{ $self }--document-2 & {
			text-align: left;
			grid-template-rows: min-content auto 1fr;
		}

		#{ $self }--manual & {
			height: 100%;
			@include respond-to(display) {
				height: 30rem;
			}
		}

		#{ $self }--manual.teaser--document-2 & {
			transition: background-color $tt-std ease;
			height: auto;
			//padding: 0;
			padding-top: 5.5rem;
			padding-bottom: 5rem;
			//background: var(--color-grey-5);
			--background-color: var(--color-grey-5) ;
			@include respond-to(display) {
				//height: 50rem;

			}

			&:hover {
				--tagline-color: var(--color-white);
				--title-color: var(--color-white);
				--subline-color: var(--color-white);
				--background-color: var(--color-mint) ;
				//background: var(--color-mint);
			}
		}
	}

	&__content-copy {
		@include respond-to(until_display) {
			display: none;
		}

		z-index: 10;

		#{ $self }--document-2 &,
		#{ $self }--document &,
		#{ $self }--job &,
		#{ $self }--blog & {
			--tagline-color: var(--color-white);
			--title-color: var(--color-white);
			--subline-color: var(--color-white);
			--date-color: var(--color-white);
			--ttr-color: var(--color-grey-1);

			transition: all 500ms ease;
			padding: 0;

			background: transparent;
			overflow: hidden;

			clip-path: var(--clip-path);

			.teaser__content {
				transition: width $tt-std ease;
				overflow: hidden;
				z-index: auto;
				background: transparent;
			}

			&:after {
				transition: transform $tt-std ease;
				//transform: translateX(100%);
				content: '';
				position: absolute;
				z-index: -1;
				top:0;
				left:0;
				width: 100%;
				height: 100%;

				background: var(--background-color-copy);
			}
		}

		#{ $self }--blog & {
			grid-row: 2;
		}


		#{ $self }--no-link & {
			display: none;
		}
	}

	&__image {

		IMG, PICTURE {
			transition: all $tt-std ease;
		}

		overflow: hidden;


		#{$self}--document-2 & {
			text-align: center;
			margin-bottom: 3rem;

			IMG, PICTURE {
				width: auto;
			}
		}

		#{ $self }--blog & {
			grid-row: 1;
		};

		#{ $self }--blog-big & {
			grid-row: 1;
			grid-column: 1;

			IMG, PICTURE {
				height: 100%;
				object-fit: cover;
			}


		};
	}

	&__tagline {
		@include font-style-teaser-tagline;

		#{ $self }--document &,
		#{ $self }--job &,
		#{ $self }--blog & {
			grid-area: top;
			color: var(--tagline-color);
		}
	}

	&__title {
		@include font-style-teaser-title;
		margin:2rem 0;


		#{ $self }--document-2 &,
		#{ $self }--document &,
		#{ $self }--job &,
		#{ $self }--blog & {
			grid-area: center;
			color: var(--title-color);
		}

		#{ $self }--document & {
			align-self: flex-end;
		}

		#{ $self }--document-2 & {
			@include font-style-headline-3;
		}

		#{ $self }--manual & {
			@include font-style-teaser-title-big;
		}
		#{ $self }--manual.teaser--document-2 & {
			@include font-style-headline-3;
		}
	}

	&__meta {
		@include font-style-teaser-tagline;
	}

	&__text {
		color: var(--title-color);

		#{$self}--blog-big & {
			margin-top: 0;
			grid-area: text;
		}
	}



	&__subline {
		@include font-style-teaser-subline;

		display: flex;
		align-items: center;

		#{ $self }--document-2 &,
		#{ $self }--document &,
		#{ $self }--job &,
		#{ $self }--blog & {
			grid-area: bottom;
			align-self: flex-end;

			color: var(--subline-color);
		}

		#{ $self }--document-2 &,
		#{ $self }--document & {
			align-self: flex-start;
		}

		#{ $self}--document-2 & {
			@include font-style-body;
		}

		.inline-svg {
			margin-right: 1rem;
		}

		svg {
			fill: var(--subline-color);
			width: auto;
			height: 3rem;
		}

		#{$self}--job & {
			svg {
				margin-right: 1rem;
			}
		}
	}



}