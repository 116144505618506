.cookie-message {
	$self: &;

	@include font-style-body-mini;

	position: fixed;
	bottom: 0;
	left:0;
	z-index: 4000;

	display: none;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;

	&--visible {
		display: flex;
	}

	width: 100%;
	height: auto;
	padding: 3rem 0;
	@include respond-to(tablet) {
		padding: 2rem 0;
	}

	background: $color-aubergine;
	color: $color-white;

	&__grid {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 2rem;

		max-width: 90%;
		padding: 0 0;
		@include respond-to(tablet) {
			align-items: center;
			max-width: 75%;
			padding: 0 2rem;
		}
		@include respond-to(display) {
			max-width: 50%;
		}
		@include respond-to(display_medium) {
			max-width: 35%;
		}

		button {
			height: 4rem;
		}
	}

	&__text {
		width: 100%;
		text-align: left;
		//margin-bottom: 2rem;

		A {
			border-bottom: 0.1rem solid $color-white;
			color: $color-white;

			&:hover {
				color: $color-mint;
				border-color: $color-mint;
			}
		}

	}
}